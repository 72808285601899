import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import { db, auth, storage, firebase, secondaryApp } from '../firebase'
import router from '../router'
import moment from 'moment'
import axios from 'axios'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cargaAction: false,
    usuario: '',

    // search clinicians
    clinicians: [],
    searchClinicianName: '',
    searchClinicianLocation: '',
    searchClinicianStatus: '',
    searchClinicianInsuranceTypesAccepted: '',
    searchClinicianLanguage: '',
    searchClinicianRegion: '',
    searchCountryOfResidence: '',
    searchBorough: '',
    searchProgram: '',
    searchTypeOfService: '',
    searchAcceptingNewClients: '',
    searchAcceptReferralsOutsideOfCatchment: '',

    filterClinicianName: '',
    filterClinicianStatus: '',
    filterClinicianInsuranceTypesAccepted: '',
    filterClinicianLanguage: '',
    filterClinicianRegion: '',
    filterCountryOfResidence: '',
    filterBorough: '',
    filterProgram: '',
    filterTypeOfService: '',
    filterAcceptingNewClients: '',
    filterAcceptReferralsOutsideOfCatchment: '',

    // search clients
    clients: [],
    searchClientName: '',
    searchClientID: '',
    searchClientClinicianName: '',
    searchClientClientCounty: '',
    searchClientClinicianRegion: '',
    searchClientClinicianBorough: '',
    searchClientEmail: '',
    searchClientPhoneNumber: '',
    searchClientStatus: '',
    searchClientNameState: '',
    searchClientIDState: '',
    searchClientClinicianNameState: '',
    searchClientClientCountyState: '',
    searchClientClinicianRegionState: '',
    searchClientClinicianBoroughState: '',
    searchClientStatusState: '',
    searchClientEmailState: '',
    searchClientPhoneNumberState: '',

    // search claims
    claims: [],
    searchClaimClinicianName: '',
    searchClaimClientName: '',
    searchClaimClaimStatus: '',
    searchClaimClinicianRegion: '',
    searchClaimClinicianStatus: '',
    searchClaimStartDate: '',
    searchClaimEndDate: '',
    searchClaimsClientID: '',
    searchClaimClinicianProgram: '',
    searchClaimClinicianNameState: '',
    searchClaimNameState: '',
    searchClaimStatusState: '',
    searchClaimClinicianRegionState: '',
    searchClaimClinicianStatusState: '',
    searchClaimStartDateState: '',
    searchClaimEndDateState: '',
    searchClaimIDState: '',
    searchClaimClinicianProgramState: '',

    // search claims approved
    claimsApproved: [],
    searchClaimApprovedClinicianName: '',
    searchClaimApprovedClinicianNameState: '',
    searchClaimApprovedClinicianRegion: '',
    searchClaimApprovedClinicianRegionState: '',
    searchClaimApprovedClinicianProgram: '',
    searchClaimApprovedClinicianProgramState: '',

    // search claims user
    claimsUser: [],
    searchClaimUserName: '',
    searchClaimUserStatus: '',

    // search client activity
    clientsActivity: [],
    searchClientActivityName: '',
    searchClientActivityStatus: '',
    searchClientActivityClinicianName: '',
    searchClientActivityClinicianStatus: '',
    searchClientActivityClientID: '',
    searchClientActivityStartDate: '',
    searchClientActivityEndDate: '',
    searchClientActivityClinicianRegion: '',
    searchClientActivityClinicianProgram: '',

    // search client activity user
    clientsActivityUser: [],
    searchClientActivityUserName: '',
    searchClientActivityUserStatus: '',

    // search reports general
    reportsGeneral: [],
    searchReportsGeneralEndDate: '',
    searchReportsGeneralStartDate: '',
    searchReportsGeneralClinicianName: '',
    searchReportsGeneralClientName: '',
    searchReportsGeneralRegion: '',
    searchReportsGeneralnoIndividualSessions : '',
    searchReportsGeneralnoGroupSessions: '',
    searchReportsGeneralnoRemoteSessions: '',
    searchReportsGeneralnoAssessmentSessions: '',
    searchReportsGeneralnoCoupleSessions: '',
    searchReportsGeneralnoFamilySessions: '',
    searchReportsGeneralisInsuranceUtilizedForClaim: '',
    searchReportsGeneralclientProgressThisMonth: '',
    searchReportsGeneralclientReportsSatisfactionWithTreatmentSessions: '',
    searchReportsGeneraldidClientProvideCopay: '',
    searchReportsGeneraldidClientUtilizeSlidingScale: '',
    searchReportsGeneralProgram: '',
    searchReportsGeneralStartDateState: '',
    searchReportsGeneralEndDateState: '',
    searchReportsGeneralClinicianNameState: '',
    searchReportsGeneralClientNameState: '',
    searchReportsGeneralnoIndividualSessionsState: '',
    searchReportsGeneralnoGroupSessionsState: '',
    searchReportsGeneralnoRemoteSessionsState: '',
    searchReportsGeneralnoAssessmentSessionsState: '',
    searchReportsGeneralnoCoupleSessionsState: '',
    searchReportsGeneralnoFamilySessionsState: '',
    searchReportsGeneralisInsuranceUtilizedForClaimState: '',
    searchReportsGeneralclientProgressThisMonthState: '',
    searchReportsGeneralclientReportsSatisfactionWithTreatmentSessionsState: '',
    searchReportsGeneraldidClientProvideCopayState: '',
    searchReportsGeneraldidClientUtilizeSlidingScaleState: '',
    searchReportsGeneralRegionState: '',
    searchReportsGeneralProgramState: '',
    // searchReportsGeneralProgress: '',
    // searchReportsGeneralLanguage: '',
    reportsGeneralClaims: [],
    reportsGeneralIndividualClientsSeen: [],

    // reports call data
    reportsCallData: [],
    searchReportsCallDataStartDate: '',
    searchReportsCallDataEndDate: '',
    searchReportsCallDataRegion: '',
    searchReportsCallDataProgress: '',
    searchReportsCallDataLanguage: '',
    searchReportsCallDataInsurance: '',
    searchReportsCallDataWarmTransfer: '',
    searchReportsCallDataProgram: '',
    searchReportsCallDataStartDateState: '',
    searchReportsCallDataEndDateState: '',
    searchReportsCallDataRegionState: '',
    searchReportsCallDataProgressState: '',
    searchReportsCallDataLanguageState: '',
    searchReportsCallDataInsuranceState: '',
    searchReportsCallDataWarmTransferState: '',
    searchReportsCallDataProgramState: '',

    // reports active treatment
    reportsActiveTreatment: [],
    reportsActiveTreatmentClaims: [],
    searchReportsActiveTreatmentStartDate: '',
    searchReportsActiveTreatmentEndDate: '',
    searchReportsActiveTreatmentRegion: '',
    searchReportsActiveTreatmentProgress: '',
    searchReportsActiveTreatmentLanguage: '',
    searchReportsActiveTreatmentInsurance: '',
    searchReportsActiveTreatmentProgram: '',
    searchReportsActiveTreatmentStartDateState: '',
    searchReportsActiveTreatmentEndDateState: '',
    searchReportsActiveTreatmentRegionState: '',
    searchReportsActiveTreatmentProgressState: '',
    searchReportsActiveTreatmentLanguageState: '',
    searchReportsActiveTreatmentInsuranceState: '',
    searchReportsActiveTreatmentProgramState: '',

    // reports discharge data
    reportsDischargeData: [],
    searchReportsDischargeDataStartDate: '',
    searchReportsDischargeDataEndDate: '',
    searchReportsDischargeDataRegion: '',
    searchReportsDischargeDataProgress: '',
    searchReportsDischargeDataLanguage: '',
    searchReportsDischargeDataInsurance: '',
    searchReportsDischargeDataProgram: '',
    searchReportsDischargeDataStartDateState: '',
    searchReportsDischargeDataEndDateState: '',
    searchReportsDischargeDataRegionState: '',
    searchReportsDischargeDataProgressState: '',
    searchReportsDischargeDataLanguageState: '',
    searchReportsDischargeDataInsuranceState: '',
    searchReportsDischargeDataProgramState: '',

    // reports insurance utilization
    reportsInsuranceUtilization: [],
    searchReportsInsuranceUtilizationStartDate: '',
    searchReportsInsuranceUtilizationEndDate: '',
    searchReportsInsuranceUtilizationClinicianName: '',
    searchReportsInsuranceUtilizationClientName: '',
    searchReportsInsuranceUtilizationRegion: '',
    searchReportsInsuranceUtilizationProgram: '',
    searchReportsInsuranceUtilizationIsClientInsured: '',
    searchReportsInsuranceUtilizationIsClientMatchedToaClinicianWhoAcceptsTheirInsurance: '',
    searchReportsInsuranceUtilizationIsTheClinicianBillingTheClientsInsurance: '',
    searchReportsInsuranceUtilizationWhyInsuranceWasNotBilled: '',

    // reports finance information
    reportsFinanceInformation: [],
    searchReportsFinanceInformationStartDate: '',
    searchReportsFinanceInformationEndDate: '',
    searchReportsFinanceInformationClinicianName: '',
    searchReportsFinanceInformationClientName: '',
    searchReportsFinanceInformationRegion: '',
    searchReportsFinanceInformationProgram: '',
    searchReportsFinanceInformationInsuranceRates: '',
    searchReportsFinanceInformationDidClientProvideCopay: '',
    searchReportsFinanceInformationDidclientUtilizeSlidingScale: '',

    // reports clinician
    reportsClinician: [],
    searchReportsClinicianStartDate: '',
    searchReportsClinicianEndDate: '',
    searchReportsClinicianClinicianName: '',
    searchReportsClinicianClientName: '',
    searchReportsClinicianRegion: '',
    searchReportsClinicianProgram: '',
    searchReportsClinicianClaimDateMonth: '',
    searchReportsClinicianClaimDateYear: '',
  },
  mutations: {
    setCargaAction(state, payload) {
      state.cargaAction = payload
    },
    nuevoUsuario(state, payload){
      if(payload) {
        state.usuario = payload
      } else {
        state.usuario = ''
      }
    },
    setClinician(state,payload) {
      state.clinicians = payload
    },
    setClient(state,payload) {
      state.clients = payload
    },
    setClaims(state,payload) {
      state.claims = payload
    },
    setClaimsApproved(state,payload) {
      state.claimsApproved = payload
    },
    setClaimsUser(state,payload) {
      state.claimsUser = payload
    },
    setClientActivity(state,payload) {
      state.clientsActivity = payload
    },
    setClientActivityUser(state,payload) {
      state.clientsActivityUser = payload
    },
    setReportsGeneral(state,payload) {
      state.reportsGeneral = payload
    },
    setReportsGeneralClaims(state,payload) {
      state.reportsGeneralClaims = payload
    },
    setReportsGeneralIndividualClientsSeen(state,payload) {
      state.reportsGeneralIndividualClientsSeen = payload
    },
    setReportsCallData(state,payload) {
      state.reportsCallData = payload
    },
    setReportsActiveTreatment(state,payload) {
      state.reportsActiveTreatment = payload
    },
    setReportsActiveTreatmentClaims(state,payload) {
      state.reportsActiveTreatmentClaims = payload
    },
    setReportsDischargeData(state,payload) {
      state.reportsDischargeData = payload
    },
    setReportsInsuranceUtilization(state,payload) {
      state.reportsInsuranceUtilization = payload
    },
    setReportsFinanceInformation(state,payload) {
      state.reportsFinanceInformation = payload
    },
    setReportsClinician(state,payload) {
      state.reportsClinician = payload
    }
  },
  actions: {
    // Configure the logged in user
    setUsuario({commit}, user) {
      if(user) {
        db.collection('usuarios').doc(user.uid).get()
        .then(userDoc => {
          const usuario = {
            nombre: userDoc.data().nombre ? userDoc.data().nombre : userDoc.data().name ? userDoc.data().name : '',
            email: userDoc.data().email,
            uid: userDoc.data().uid,
            foto: userDoc.data().foto,
            role: userDoc.data().role,
            ability: userDoc.data().ability
          }
          
          const user = firebase.auth().currentUser;

          user.updateProfile({
            displayName: userDoc.data().firstName + ' ' + userDoc.data().lastName
          })
          // .then(() => {
          //   console.log('display name inserted')
          // })

          localStorage.setItem('usAb', JSON.stringify({ability: userDoc.data().ability}))
          commit('nuevoUsuario', usuario)
        })
        .catch(error => console.log(error))
      } else {
        auth.signOut()
        commit('nuevoUsuario',null)
        localStorage.removeItem('usAb')
        localStorage.removeItem('logActy')
        window.location.href = '/login'
      }
    },
    // register usuario
    crearUsuario({commit}, usuario) {
      auth.createUserWithEmailAndPassword(usuario.email,usuario.password)
      .then(res => {
        var uid = res.user.uid
        
        const usuarioObject = {
          nombre: usuario.email,
          email: usuario.email,
          uid: uid,
          foto: '',
          role: 'clinician',
          status: 'inactive',
          ability: [ 
            {
              action: 'manage',
              subject: 'all'
            }
          ]
        }

        // verificar si el usuario ya tiene documento
        db.collection('usuarios').where('email', '==', usuarioObject.email).get()
        .then(itemUser => {
          // el correo ya existe
          if(itemUser.size >= 1) {
            this._vm.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Try with other email`,
                icon: 'InfoIcon',
                variant: 'warning',
                text: `The email with which you want to register cannot be added, because it already exists`,
              },
            })
          } else {
            // correo bienvenida
            var obj = {
              email: usuario.email
            }
            
            // var config = {
            //   'url': 'https://us-central1-nycpg-69e96.cloudfunctions.net/welcomeMail',
            //   'method': 'POST',
            //   'timeout': 0,
            //   'headers': {
            //     'Content-Type': 'application/json'
            //   },
            //   'data': JSON.stringify(obj),
            // };
      
            // this._vm.axios(config)
            // .then(response => {
            //   console.log(response.data)
            // })
            // .catch(e => {
            //     console.log(e)
            // })

            db.collection('usuarios').doc(uid).set(usuarioObject)
            .then(() => {
              this._vm.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `User successfully registered`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                },
              })
              
              this._vm.$ability.update(usuarioObject.ability)
              commit('nuevoUsuario', usuarioObject)
              router.replace(getHomeRouteForLoggedInUser(usuarioObject.role))
            })
            .catch(e => console.log(e))
          }
        })
        .catch(error => {
          console.log(error)
        })
      })
      .catch(error => {
        if(error.code === 'auth/weak-password') {
          this._vm.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Error`,
              icon: 'CoffeeIcon',
              variant: 'error',
              text: `The password cannot be less than 6 characters`,
            },
          })
        } else if(error.code === 'auth/email-already-in-use') {
          this._vm.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Error`,
              icon: 'CoffeeIcon',
              variant: 'error',
              text: `The email is already taken`,
            },
          })
        } else {
          this._vm.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Error`,
              icon: 'CoffeeIcon',
              variant: 'error',
              text: `Check that you have entered the data correctly`,
            },
          })
        }
      })
    },
    async agregarUsuarios({commit}, payload) {
      // agregar usuario por medio de otro usuario
      var makeid = (length) => {
          var result           = '';
          var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
          var charactersLength = characters.length;
          for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
          }
          return result;
      };

      try {
        var pass = payload.password ? payload.password : makeid(8)
        const signInEmail = await secondaryApp.auth().createUserWithEmailAndPassword(payload.email,pass)

        let userObject = {
          nombre: payload.nombre,
          firstName: payload.firstName ? payload.firstName : '',
          lastName: payload.lastName ? payload.lastName : '',
          email: payload.email,
          uid: signInEmail.user.uid,
          foto: '',
          role: payload.role,
          contact: payload.contact ? payload.contact : '',
          permissions: payload.permissions,
          status: 'active',
          ability: [ 
            {
              action: 'manage',
              subject: 'all'
            }
          ]
        }

        db.collection('usuarios').doc(signInEmail.user.uid).set(userObject)
        .then(() => {
          this._vm.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `User ${payload.nombre} created`,
              icon: 'CoffeeIcon',
              variant: 'success',
              text: `User created successfully!`,
            },
          })
          auth.sendPasswordResetEmail(payload.email)

          if(payload.role !== 'admin') {
            // var obj = {
            //   email: payload.email,
            // };
    
            // var config = {
            //   method: 'POST',
            //   url: 'https://us-central1-nycpg-69e96.cloudfunctions.net/invitationMail',
            //   headers: { 
            //     'Content-Type': 'application/json'
            //   },
            //   data : JSON.stringify(obj),
            // };
    
            // this._vm.axios(config)
            // .then(() => {
            //   this._vm.$toast({
            //     component: ToastificationContent,
            //     position: 'top-right',
            //     props: {
            //       title: `Invitation has been sent`,
            //       icon: 'CoffeeIcon',
            //       variant: 'success',
            //       text: `The invitation has been sent to ${payload.email}`,
            //     },
            //   })
            // })
            // .catch(function (error) {
            //   console.log(error);
            // });
          }
        })
        .catch(error => {
          console.log(error)
        })
      } catch (error) {
        // El Usuario ya existe
        if(error.code === 'auth/email-already-in-use') {
          this._vm.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Try other email`,
              icon: 'InfoIcon',
              variant: 'warning',
              text: `The email you want to register with cannot be added, because it already exists`,
            },
          })
        } else {
          console.log(error)
        }
      }
    },
    // Create clinician details
    async createClinician({commit}, payload) {
      // create user clinician
      var makeid = (length) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      };

      var makeCodigo = (length) => {
        var result           = '';
        var characters       = '0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      };

      try {
        var pass = payload.password ? payload.password : makeid(8)
        const signInEmail = await secondaryApp.auth().createUserWithEmailAndPassword(payload.email,pass)

        let userObject = {}
        if(payload.role === 'clinician-staff') {
          userObject = {
            uid: signInEmail.user.uid,
            status: 'active',
            statusReferral: 'active',
            name: payload.firstName + ' ' + payload.lastName,
            nombre: payload.firstName + ' ' + payload.lastName,
            foto: '',
            firstName: payload.firstName,
            lastName: payload.lastName,
            agency: payload.agency,
            email: payload.email,
            countryOfResidence: payload.countryOfResidence,
            region: payload.region,
            borough: payload.borough,
            program: payload.program,
            programType: payload.programType,

            // details
            licenseType: payload.licenseType,
            licenseNumber: payload.licenseNumber,
            insuranceTypesAccepted: payload.insuranceTypesAccepted,
            specialities: payload.specialities,
            languages: payload.languages,
            mailingAddress: payload.mailingAddress,
            city: payload.city,
            state: payload.state,
            zip: payload.zip,
            contact: payload.contact ? payload.contact : '',
            // acceptingNewClients: payload.acceptingNewClients,
            // acceptingReferralsOutsideOfCatchment: payload.acceptingReferralsOutsideOfCatchment,

            // Attributes
            attributes: payload.attributes,
            providesRemoteAndOrOffSiteSessionsIfRequired: payload.providesRemoteAndOrOffSiteSessionsIfRequired,

            // notes
            notes: payload.notes,
            role: payload.role,
            permissions: [],
            ability: [
              {
                action: 'manage',
                subject: 'all'
              }
            ],
            dependency: payload.dependency
          }
        } else {
          userObject = {
            uid: signInEmail.user.uid,
            status: 'active',
            statusReferral: 'active',
            nombre: payload.firstName + ' ' + payload.lastName,
            foto: '',
            firstName: payload.firstName,
            lastName: payload.lastName,
            agency: payload.agency,
            email: payload.email,
            countryOfResidence: payload.countryOfResidence,
            region: payload.region,
            borough: payload.borough,
            program: payload.program,
            programType: payload.programType,

            // details
            licenseType: payload.licenseType,
            licenseNumber: payload.licenseNumber,
            insuranceTypesAccepted: payload.insuranceTypesAccepted,
            specialities: payload.specialities,
            languages: payload.languages,
            mailingAddress: payload.mailingAddress,
            city: payload.city,
            state: payload.state,
            zip: payload.zip,
            contact: payload.contact ? payload.contact : '',
            // acceptingNewClients: payload.acceptingNewClients,
            // acceptingReferralsOutsideOfCatchment: payload.acceptingReferralsOutsideOfCatchment,

            // Attributes
            attributes: payload.attributes,
            providesRemoteAndOrOffSiteSessionsIfRequired: payload.providesRemoteAndOrOffSiteSessionsIfRequired,

            // notes
            notes: payload.notes,
            role: payload.role,
            permissions: [],
            ability: [ 
              {
                action: 'manage',
                subject: 'all'
              }
            ]
          }
        }
  
        // check if id already exists
        const functionAddClinician = () => {
          var noCodigo = makeCodigo(6)
          // var noCodigoInt = parseInt(noCodigo,10)
          db.collection('usuarios').where('codigo','==',noCodigo).get()
          .then(itemClinician => {
            if(itemClinician.size >= 1) {
              functionAddClinician()
            } else {
              userObject.codigo = noCodigo
              db.collection('usuarios').doc(userObject.uid).set(userObject)
              .then(() => {
                this._vm.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: payload.role === 'clinician-staff' ? `Clinician Staff created` : `Clinician created`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: `Clinician Staff ${payload.firstName + ' ' + payload.lastName} created successfully!`,
                  },
                })

                db.collection('usuarios').doc(userObject.uid).collection('details').doc('trackingDetail').set(payload.objectTracking)
                .then(() => {
                  if(payload.objectTracking.datePickers && payload.objectTracking.datePickers.length >= 1) {
                    var licenseExpirationDateTrack = null
                    var activeDateTrack = null
                    var expirationDateTrack = null
                    var malpracticeInsuranceExpirationDateTrack = null
                    malpracticeInsuranceExpirationDateTrack = payload.objectTracking.datePickers[5].value ? payload.objectTracking.datePickers[5].value : null
                    licenseExpirationDateTrack = payload.objectTracking.datePickers[7].value ? payload.objectTracking.datePickers[7].value : null
                    activeDateTrack = payload.objectTracking.datePickers[8].value ? payload.objectTracking.datePickers[8].value : null
                    expirationDateTrack = payload.objectTracking.datePickers[9].value ? payload.objectTracking.datePickers[9].value : null
          
                    db.collection('usuarios').doc(userObject.uid).update({
                      licenseExpirationDateTrack: licenseExpirationDateTrack,
                      activeDateTrack: activeDateTrack,
                      expirationDateTrack: expirationDateTrack,
                      malpracticeInsuranceExpirationDateTrack: malpracticeInsuranceExpirationDateTrack
                    })
                  } else {
                    db.collection('usuarios').doc(userObject.uid).update({
                      licenseExpirationDateTrack: null,
                      activeDateTrack: null,
                      expirationDateTrack: null,
                      malpracticeInsuranceExpirationDateTrack: null
                    })
                  }
                })
                .catch(error => {
                  console.log(error)
                })
                auth.sendPasswordResetEmail(payload.email)
                
                payload.role === 'clinician-staff' ? null : router.push({ name: 'all-clinicians' })
              })
              .catch(error => {
                console.log(error)
              })
            }
          })
          .catch(error => console.log(error))
        }
        functionAddClinician()
      } catch (error) {
        // El Usuario ya existe
        if(error.code === 'auth/email-already-in-use') {
          this._vm.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Try other email`,
              icon: 'InfoIcon',
              variant: 'warning',
              text: `The email you want to register with cannot be added, because it already exists`,
            },
          })
        } else {
          console.log(error)
        }
      }
    },
    // active clinician
    activateClinician({commit}, payload) {
      db.collection('usuarios').doc(payload).update({
        status: 'active'
      })
      .then(() => {
        this._vm.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Clinician activated`,
            icon: 'CoffeeIcon',
            variant: 'success'
          },
        })
      })
      .catch(e => console.log(e))
    },
    // deactivate clinician
    deactivateClinician({commit}, payload) {
      db.collection('usuarios').doc(payload).update({
        status: 'inactive'
      })
      .then(() => {
        this._vm.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Clinician disabled`,
            icon: 'CoffeeIcon',
            variant: 'success'
          },
        })
      })
      .catch(e => console.log(e))
    },
    // edit clinician details
    updateClinicianDetails({commit}, payload) {
      if(payload.role === 'clinician-staff') {
        let userObject = {
          nombre: payload.firstName + ' ' + payload.lastName,
          name: payload.firstName + ' ' + payload.lastName,
          foto: '',
          firstName: payload.firstName,
          lastName: payload.lastName,
          agency: payload.agency,
          email: payload.email,
          countryOfResidence: payload.countryOfResidence,
          region: payload.region,
          borough: payload.borough,
          program: payload.program,
          programType: payload.programType,

          // details
          licenseType: payload.licenseType,
          licenseNumber: payload.licenseNumber,
          insuranceTypesAccepted: payload.insuranceTypesAccepted,
          specialities: payload.specialities,
          languages: payload.languages,
          mailingAddress: payload.mailingAddress,
          city: payload.city,
          state: payload.state,
          zip: payload.zip,
          contact: payload.contact ? payload.contact : '',
          // acceptingNewClients: payload.acceptingNewClients,
          // acceptingReferralsOutsideOfCatchment: payload.acceptingReferralsOutsideOfCatchment,

          // Attributes
          attributes: payload.attributes,
          providesRemoteAndOrOffSiteSessionsIfRequired: payload.providesRemoteAndOrOffSiteSessionsIfRequired,

          // notes
          notes: payload.notes,
          // role: payload.role,
          permissions: []
        }

        db.collection('usuarios').doc(payload.id).update(userObject)
        .then(() => {
          this._vm.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `User updated`,
              icon: 'CoffeeIcon',
              variant: 'success',
              text: `User updated successfully!`,
            },
          })

          this._vm.$http.get(`https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/update-clinician/relations/${payload.id}`)
          .then(res => {
            console.log('relations updated')
          })
          .catch(err => {
            console.log(err)
          })
        })
      } else {
        let userObject = {
          nombre: payload.firstName + ' ' + payload.lastName,
          foto: '',
          firstName: payload.firstName,
          lastName: payload.lastName,
          agency: payload.agency,
          email: payload.email,
          countryOfResidence: payload.countryOfResidence,
          region: payload.region,
          borough: payload.borough,
          program: payload.program,
          programType: payload.programType,

          // details
          licenseType: payload.licenseType,
          licenseNumber: payload.licenseNumber,
          insuranceTypesAccepted: payload.insuranceTypesAccepted,
          specialities: payload.specialities,
          languages: payload.languages,
          mailingAddress: payload.mailingAddress,
          city: payload.city,
          state: payload.state,
          zip: payload.zip,
          contact: payload.contact ? payload.contact : '',
          // acceptingNewClients: payload.acceptingNewClients,
          // acceptingReferralsOutsideOfCatchment: payload.acceptingReferralsOutsideOfCatchment,

          // Attributes
          attributes: payload.attributes,
          providesRemoteAndOrOffSiteSessionsIfRequired: payload.providesRemoteAndOrOffSiteSessionsIfRequired,

          // notes
          notes: payload.notes,
          role: payload.role,
          permissions: []
        }

        db.collection('usuarios').doc(payload.id).update(userObject)
        .then(() => {
          this._vm.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `User updated`,
              icon: 'CoffeeIcon',
              variant: 'success',
              text: `User updated successfully!`,
            },
          })

          this._vm.$http.get(`https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/update-clinician/relations/${payload.id}`)
          .then(res => {
            console.log('relations updated')
          })
          .catch(err => {
            console.log(err)
          })
          
          // update referral details
          if(payload.program !== 'PGRC - OASAS Program') {
            db.collection('usuarios').doc(payload.id).collection('details').doc('referralDetail').get()
            .then(docReferral => {
              if(docReferral.exists) {
                if(docReferral.data().referralDetail && docReferral.data().referralDetail.length >= 1) {
                  var changesReferral = []
                  docReferral.data().referralDetail.forEach(ele => {
                    ele.acceptingNewClients = ''
                    ele.acceptReferralsOutsideOfCatchment = ''
                    changesReferral.push(ele)
                  })

                  db.collection('usuarios').doc(payload.id).collection('details').doc('referralDetail').set({referralDetail: changesReferral})
                }
              }
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
      }
    },
    // edit clinician referral details
    updateClinicianReferralDetails({commit}, payload) {
      db.collection('usuarios').doc(payload.id).collection('details').doc('referralDetail').set({referralDetail: payload.data})
      .then(() => {
        this._vm.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Clinician updated`,
            icon: 'CoffeeIcon',
            variant: 'success',
            text: `Clinician referral details updated successfully!`,
          },
        })

        if(payload.data && payload.data.length >= 1) {
          var acceptingNewClients = []
          var acceptReferralsOutsideOfCatchment = []
          var typeOfService = []
          payload.data.forEach(ele => {
            ele.typeService ? typeOfService.push(ele.typeService) : null
            ele.acceptingNewClients ? acceptingNewClients.push(ele.acceptingNewClients) : null
            ele.acceptReferralsOutsideOfCatchment ? acceptReferralsOutsideOfCatchment.push(ele.acceptReferralsOutsideOfCatchment) : null
          })

          db.collection('usuarios').doc(payload.id).update({
            typeOfService: typeOfService,
            acceptingNewClients: acceptingNewClients,
            acceptReferralsOutsideOfCatchment: acceptReferralsOutsideOfCatchment,
            referralDetail: payload.data
          })
        } else {
          db.collection('usuarios').doc(payload.id).update({
            typeOfService: [],
            acceptingNewClients: [],
            acceptReferralsOutsideOfCatchment: [],
            referralDetail: payload.data
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
    },
    // edit clinician tracking details
    updateClinicianTrackingDetails({commit}, payload) {
      db.collection('usuarios').doc(payload.id).collection('details').doc('trackingDetail').set(payload.data)
      .then(() => {
        this._vm.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Clinician updated`,
            icon: 'CoffeeIcon',
            variant: 'success',
            text: `Clinician tracking details updated successfully!`,
          },
        })

        if(payload.data.datePickers && payload.data.datePickers.length >= 1) {
          var licenseExpirationDateTrack = null
          var activeDateTrack = null
          var expirationDateTrack = null
          var malpracticeInsuranceExpirationDateTrack = null
          malpracticeInsuranceExpirationDateTrack = payload.data.datePickers[5].value ? payload.data.datePickers[5].value : null
          licenseExpirationDateTrack = payload.data.datePickers[7].value ? payload.data.datePickers[7].value : null
          activeDateTrack = payload.data.datePickers[8].value ? payload.data.datePickers[8].value : null
          expirationDateTrack = payload.data.datePickers[9].value ? payload.data.datePickers[9].value : null

          db.collection('usuarios').doc(payload.id).update({
            licenseExpirationDateTrack: licenseExpirationDateTrack,
            activeDateTrack: activeDateTrack,
            expirationDateTrack: expirationDateTrack,
            malpracticeInsuranceExpirationDateTrack: malpracticeInsuranceExpirationDateTrack
          })
        } else {
          db.collection('usuarios').doc(payload.id).update({
            licenseExpirationDateTrack: null,
            activeDateTrack: null,
            expirationDateTrack: null,
            malpracticeInsuranceExpirationDateTrack: null
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
    },
    // edit user data through another user
    editarUsuario({commit}, payload) {
      db.collection('usuarios').doc(payload.id).update({
        nombre: payload.firstName + ' ' + payload.lastName,
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        contact: payload.contact,
        permissions: payload.permissions,
      })
      .then(() => {
        this._vm.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `User edited successfully`,
            icon: 'CoffeeIcon',
            variant: 'success',
          },
        })
      })
      .catch(error => {
        console.log(error)
      })
    },
    // edit user from profile settings
    async editarGeneralUsuario({commit}, payload) {
      try {
        // console.log(payload)
        var dataToUpdate = {}
        let imagenes = []
        if (payload.fileImg) {
          var objImgs = {}
          var refImg = await storage.ref().child(payload.id).child(payload.fileImg.name)
          var res = await refImg.put(payload.fileImg)
          var urlDescarga = await refImg.getDownloadURL()
          objImgs.imagen = urlDescarga
          objImgs.mimeType = payload.fileImg.type
          objImgs.size = payload.fileImg.size
          objImgs.lastModified = payload.fileImg.lastModified
          objImgs.name = payload.fileImg.name
          imagenes.push(objImgs)
          dataToUpdate.foto = urlDescarga
        }
        dataToUpdate.nombre = payload.firstName + ' ' + payload.lastName
        dataToUpdate.firstName = payload.firstName
        dataToUpdate.lastName = payload.lastName
        dataToUpdate.email = payload.email

        if(payload.contact) {
          dataToUpdate.contact = payload.contact
        } else {
          dataToUpdate.contact = ''
        }
        
        db.collection('usuarios').doc(payload.id).update(dataToUpdate)
        .then(() => {
          this._vm.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `User updated`,
              icon: 'CoffeeIcon',
              variant: 'success',
              text: `User updated successfully`,
            },
          })
        })
        .catch(error => console.log(error))
      } catch(error) {
        console.log(error)
      }
    },
    // Taxonomies Clinicians
    updateProfileTaxonomies({commit}, payload) {
      // submit
      db.collection('taxonomies').doc('clinician').update(payload)
      // .then(() => {
      //   this._vm.$toast({
      //     component: ToastificationContent,
      //     position: 'top-right',
      //     props: {
      //       title: `Taxonomies updated`,
      //       icon: 'CoffeeIcon',
      //       variant: 'success',
      //       text: `Taxonomies updated successfully`,
      //     },
      //   })
      // })
      .catch(error => console.log(error))
    },
    updateClinicianDetailsTaxonomies({commit}, payload) {
      db.collection('taxonomies').doc('clinician').update(payload)
      // .then(() => {
      //   this._vm.$toast({
      //     component: ToastificationContent,
      //     position: 'top-right',
      //     props: {
      //       title: `Taxonomies updated`,
      //       icon: 'CoffeeIcon',
      //       variant: 'success',
      //       text: `Taxonomies updated successfully`,
      //     },
      //   })
      // })
      .catch(error => console.log(error))
    },
    updateTrackingDetailsTaxonomies({commit}, payload) {
      db.collection('taxonomies').doc('clinician').update(payload)
      .then(() => {
        this._vm.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Taxonomies updated`,
            icon: 'CoffeeIcon',
            variant: 'success',
            text: `Taxonomies updated successfully`,
          },
        })
      })
      .catch(error => console.log(error))
    },
    // Taxonomies Clients
    updateTaxonomiesClients({commit}, payload) {
      db.collection('taxonomies').doc('client').update(payload)
      .then(() => {
        this._vm.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Taxonomies clients updated`,
            icon: 'CoffeeIcon',
            variant: 'success',
            text: `Taxonomies clients updated successfully`,
          },
        })
      })
      .catch(error => console.log(error))
    },
    // create client
    createClient({commit}, payload) {
      var makeid = (length) => {
        var result           = '';
        var characters       = '0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      };

      // check if id already exists
      const functionAddClient = () => {
        var noCodigo = makeid(6)
        // var noCodigoInt = parseInt(noCodigo,10)
        db.collection('clients').where('id','==',noCodigo).get()
        .then(itemClient => {
          if(itemClient.size >= 1) {
            functionAddClient()
          } else {
            payload.id = noCodigo
            db.collection('clients').add(payload)
            .then((docRef) => {
              // console.log(payload.id_import)
              // console.log(`Client #${noCodigo} created successfully`)
              this._vm.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Client #${noCodigo} created successfully`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                },
              })
              // router.push({name:'clients'})
              router.push({ name: 'edit-client', params: {id: docRef.id, destination: 'engagement'} })
            })
            .catch(error => console.log(error))
          }
        })
        .catch(error => console.log(error))
      }
      functionAddClient()
    },
    // update client
    updateClient({commit}, payload) {
      db.collection('clients').doc(payload.id).update(payload.data)
      .then(() => {
        this._vm.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Client updated`,
            icon: 'CoffeeIcon',
            variant: 'success',
          },
        })

        
        this._vm.$http.get(`https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/update-client/relations/${payload.id}`)
        .then(res => {
          console.log('relations updated')
        })
        .catch(err => {
          console.log(err)
        })

        // // update data client claims
        // db.collection('clientsClaims').where('client', '==', payload.id).get()
        // .then(docsClaims => {
        //   if(docsClaims.size >= 1) {
        //     docsClaims.forEach(dataClaim => {
        //       // update data client of claim
        //       if(dataClaim.data().client) {
        //         db.collection('clients').doc(payload.id).get()
        //         .then(docClient => {
        //           db.collection('clientsClaims').doc(dataClaim.id).update({
        //             clientID: docClient.data().id,
        //             clientName: payload.data.firstName + ' ' + payload.data.lastname,
        //             clientStatus: docClient.data().status,
        //             languageClient: payload.data.primaryLanguage,
        //             regionClient: payload.data.countryOfResidence
        //           })
        //         })
        //       }
        //     })
        //   }
        // })

        // // update data client and clinician
        // db.collection('clientsActivity').where('client', '==', payload.id).get()
        // .then(docsCA => {
        //   if(docsCA.size >= 1) {
        //     docsCA.forEach(dataCA => {
        //       // db.collection('usuarios').where('uid', 'in', dataCA.data().clinician).get()
        //       // .then(docsUsers => {
        //       //   if(docsUsers.size >= 1) {

        //           db.collection('clients').doc(dataCA.data().client).get()
        //           .then(itemClient => {
        //             db.collection('clientsActivity').doc(dataCA.id).update({
        //               clientID: '#' + itemClient.data().id,
        //               clientName: itemClient.data().firstName + ' ' + itemClient.data().lastName,
        //             })
        //           })
        //         // }
        //       // })
        //     })
        //   }
        // })
      })
      .catch(error => console.log(error))
    },
    // register client activity
    createClientActivity({commit}, payload) {
      db.collection('clientsActivity').add(payload)
      .then((docRef) => {
        this._vm.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Client activity registered`,
            icon: 'CoffeeIcon',
            variant: 'success',
          },
        })
        
        if(payload.dateOfAdmission && payload.removeRecordDischargeData) {
          db.collection('clientsActivity').doc(payload.id).update({
            activityStatusLogical: 'Admitted'
          })
          .catch(error => console.log(error))

          // update data client clientActivity
          db.collection('clientsActivity').doc(payload.id).get()
          .then(dataCA => {
                var statusLogicOnHold = ''
                if(dataCA.data().dateOfAdmission && dataCA.data().removeRecordDischargeData) {
                    statusLogicOnHold = dataCA.data().activityStatus === 'On Hold' ? 'Admitted (On Hold)' : 'Admitted'
                } else if(dataCA.data().dateOfAdmission && (!dataCA.data().dateOfDischarge || dataCA.data().dateOfDischarge === 'N/A')) {
                    statusLogicOnHold = dataCA.data().activityStatus === 'On Hold' ? 'Admitted (On Hold)' : 'Admitted'
                } else if(dataCA.data().dateOfDischarge && dataCA.data().dateOfDischarge !== 'N/A') {
                    statusLogicOnHold = dataCA.data().activityStatus === 'On Hold' ? 'Discharged (On Hold)' : 'Discharged'
                } else {
                    statusLogicOnHold = dataCA.data().activityStatus
                }
                db.collection('clients').doc(dataCA.data().client).update({
                  statusAct: 'Admitted',
                  statusLogicOnHold: statusLogicOnHold
                })
          })
        } else if(payload.dateOfAdmission && (!payload.dateOfDischarge || payload.dateOfDischarge === 'N/A')) {
          db.collection('clientsActivity').doc(docRef.id).update({
            activityStatusLogical: 'Admitted'
          })
          .catch(error => console.log(error))

          var statusLogicOnHold = ''
          if(payload.dateOfAdmission && payload.removeRecordDischargeData) {
              statusLogicOnHold = payload.activityStatus === 'On Hold' ? 'Admitted (On Hold)' : 'Admitted'
          } else if(payload.dateOfAdmission && (!payload.dateOfDischarge || payload.dateOfDischarge === 'N/A')) {
              statusLogicOnHold = payload.activityStatus === 'On Hold' ? 'Admitted (On Hold)' : 'Admitted'
          } else if(payload.dateOfDischarge && payload.dateOfDischarge !== 'N/A') {
              statusLogicOnHold = payload.activityStatus === 'On Hold' ? 'Discharged (On Hold)' : 'Discharged'
          } else {
              statusLogicOnHold = payload.activityStatus
          }
          // update statusAct client
          db.collection('clients').doc(payload.client).update({
            statusAct: 'Admitted',
            statusLogicOnHold: statusLogicOnHold
          })
        } else if(payload.dateOfDischarge && payload.dateOfDischarge !== 'N/A') {
          db.collection('clientsActivity').doc(docRef.id).update({
            activityStatusLogical: 'Discharged'
          })
          .catch(error => console.log(error))

          var statusLogicOnHold = ''
          if(payload.dateOfAdmission && payload.removeRecordDischargeData) {
              statusLogicOnHold = payload.activityStatus === 'On Hold' ? 'Admitted (On Hold)' : 'Admitted'
          } else if(payload.dateOfAdmission && (!payload.dateOfDischarge || payload.dateOfDischarge === 'N/A')) {
              statusLogicOnHold = payload.activityStatus === 'On Hold' ? 'Admitted (On Hold)' : 'Admitted'
          } else if(payload.dateOfDischarge && payload.dateOfDischarge !== 'N/A') {
              statusLogicOnHold = payload.activityStatus === 'On Hold' ? 'Discharged (On Hold)' : 'Discharged'
          } else {
              statusLogicOnHold = payload.activityStatus
          }
          // update statusAct client
          db.collection('clients').doc(payload.client).update({
            statusAct: 'Discharged',
            statusLogicOnHold: statusLogicOnHold
          })
        }
        
        if(!payload.dateOfAdmission && (!payload.dateOfDischarge || payload.dateOfDischarge === 'N/A')){
          db.collection('clientsActivity').doc(docRef.id).update({
            activityStatusLogical: payload.activityStatus
          })
          .catch(error => console.log(error))

          var statusLogicOnHold = ''
          if(payload.dateOfAdmission && payload.removeRecordDischargeData) {
              statusLogicOnHold = payload.activityStatus === 'On Hold' ? 'Admitted (On Hold)' : 'Admitted'
          } else if(payload.dateOfAdmission && (!payload.dateOfDischarge || payload.dateOfDischarge === 'N/A')) {
              statusLogicOnHold = payload.activityStatus === 'On Hold' ? 'Admitted (On Hold)' : 'Admitted'
          } else if(payload.dateOfDischarge && payload.dateOfDischarge !== 'N/A') {
              statusLogicOnHold = payload.activityStatus === 'On Hold' ? 'Discharged (On Hold)' : 'Discharged'
          } else {
              statusLogicOnHold = payload.activityStatus
          }
          // update statusAct client
          db.collection('clients').doc(payload.client).update({
            statusAct: payload.activityStatus,
            statusLogicOnHold: statusLogicOnHold
          })
        }

        this._vm.$http.get(`https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/create-activity/relations/${docRef.id}`)
        .then(res => {
          console.log('relations updated')
        })
        .catch(err => {
          console.log(err)
        })

        // update data client and clinician
        // db.collection('clientsActivity').doc(docRef.id).get()
        // .then(dataCA => {
        //   db.collection('usuarios').where('uid', 'in', dataCA.data().clinician).get()
        //   .then(docsUsers => {
        //     if(docsUsers.size >= 1) {
        //       var clinicianName = ''
        //       var clinicianStatus = ''
        //       var clinicianNameConcat = ''
        //       var clinicianStatusStatus = ''
        //       var arrayClinicianName = []
        //       var arrayClinicianStatus = []

        //       var clientClinicianRegion = []
        //       var clientClinicianBorough = []
        //       var clientClinicianNombre = []
        //       docsUsers.forEach(docUser => {
        //           // if(dataCA.data().clinician.includes(docUser.id)) {
        //           clinicianName = docUser.data().agency + ' · ' + docUser.data().firstName + ' ' + docUser.data().lastName + ' (#' + docUser.data().codigo + ')'
        //           clinicianStatus = docUser.data().status ? docUser.data().status : 'inactive'
        //           clinicianNameConcat = clinicianNameConcat.concat(clinicianName, ', ')
        //           clinicianStatusStatus = clinicianStatusStatus.concat(clinicianStatus, ', ')
        //           arrayClinicianName.push(clinicianName.toLowerCase())
        //           arrayClinicianStatus.push(clinicianStatus.toLowerCase())
        //           // }

        //           clientClinicianRegion.push(docUser.data().region)
        //           clientClinicianBorough.push(docUser.data().borough)
        //           clientClinicianNombre.push(docUser.data().agency + ' · ' + docUser.data().firstName + ' ' + docUser.data().lastName + ' (#' + docUser.data().codigo + ')')
        //       })
        //       clinicianNameConcat = clinicianNameConcat.replace(/,\s*$/, '')
        //       clinicianStatusStatus = clinicianStatusStatus.replace(/,\s*$/, '')

        //       db.collection('clients').doc(dataCA.data().client).get()
        //       .then(itemClient => {

        //         db.collection('clients').doc(dataCA.data().client).update({
        //           clientClinicianRegion: clientClinicianRegion,
        //           clientClinicianBorough: clientClinicianBorough,
        //           clientClinicianNombre: clientClinicianNombre
        //         })

        //         db.collection('clientsActivity').doc(docRef.id).update({
        //           clientID: '#' + itemClient.data().id,
        //           clientName: itemClient.data().firstName + ' ' + itemClient.data().lastName,
        //           clinicianName: clinicianNameConcat,
        //           clinicianStatus: clinicianStatusStatus,
        //           arrayClinicianName: arrayClinicianName,
        //           arrayClinicianStatus: arrayClinicianStatus,
        //         })
        //       })
        //     }
        //   })
        // })
      })
      .catch(error => console.log(error))
    },
    // update client activity
    updateClientActivity({commit}, payload) {
      db.collection('clientsActivity').doc(payload.id).update(payload.data)
      .then(() => {
        // verify status admitted and discharged
        if(payload.data.dateOfAdmission && payload.data.removeRecordDischargeData) {
          db.collection('clientsActivity').doc(payload.id).update({
            activityStatusLogical: 'Admitted'  
          })
          .catch(error => console.log(error))

          // update data client clientActivity
          db.collection('clientsActivity').doc(payload.id).get()
          .then(dataCA => {
            var statusLogicOnHold = ''
            if(dataCA.data().dateOfAdmission && dataCA.data().removeRecordDischargeData) {
                statusLogicOnHold = dataCA.data().activityStatus === 'On Hold' ? 'Admitted (On Hold)' : 'Admitted'
            } else if(dataCA.data().dateOfAdmission && (!dataCA.data().dateOfDischarge || dataCA.data().dateOfDischarge === 'N/A')) {
                statusLogicOnHold = dataCA.data().activityStatus === 'On Hold' ? 'Admitted (On Hold)' : 'Admitted'
            } else if(dataCA.data().dateOfDischarge && dataCA.data().dateOfDischarge !== 'N/A') {
                statusLogicOnHold = dataCA.data().activityStatus === 'On Hold' ? 'Discharged (On Hold)' : 'Discharged'
            } else {
                statusLogicOnHold = dataCA.data().activityStatus
            }
            // update statusAct client
            db.collection('clients').doc(dataCA.data().client).update({
              statusAct: 'Admitted',
              statusLogicOnHold: statusLogicOnHold
            })
          })
        } else if(payload.data.dateOfAdmission && (!payload.data.dateOfDischarge || payload.data.dateOfDischarge === 'N/A')) {
          db.collection('clientsActivity').doc(payload.id).update({
            activityStatusLogical: 'Admitted'  
          })
          .catch(error => console.log(error))

          // update data client clientActivity
          db.collection('clientsActivity').doc(payload.id).get()
          .then(dataCA => {
            var statusLogicOnHold = ''
            if(dataCA.data().dateOfAdmission && dataCA.data().removeRecordDischargeData) {
                statusLogicOnHold = dataCA.data().activityStatus === 'On Hold' ? 'Admitted (On Hold)' : 'Admitted'
            } else if(dataCA.data().dateOfAdmission && (!dataCA.data().dateOfDischarge || dataCA.data().dateOfDischarge === 'N/A')) {
                statusLogicOnHold = dataCA.data().activityStatus === 'On Hold' ? 'Admitted (On Hold)' : 'Admitted'
            } else if(dataCA.data().dateOfDischarge && dataCA.data().dateOfDischarge !== 'N/A') {
                statusLogicOnHold = dataCA.data().activityStatus === 'On Hold' ? 'Discharged (On Hold)' : 'Discharged'
            } else {
                statusLogicOnHold = dataCA.data().activityStatus
            }
            // update statusAct client
            db.collection('clients').doc(dataCA.data().client).update({
              statusAct: 'Admitted',
              statusLogicOnHold: statusLogicOnHold
            })
          })
        } else if(payload.data.dateOfDischarge && payload.data.dateOfDischarge !== 'N/A') {
          db.collection('clientsActivity').doc(payload.id).update({
            activityStatusLogical: 'Discharged'  
          })
          .catch(error => console.log(error))

          // update data client clientActivity
          db.collection('clientsActivity').doc(payload.id).get()
          .then(dataCA => {
            var statusLogicOnHold = ''
            if(dataCA.data().dateOfAdmission && dataCA.data().removeRecordDischargeData) {
                statusLogicOnHold = dataCA.data().activityStatus === 'On Hold' ? 'Admitted (On Hold)' : 'Admitted'
            } else if(dataCA.data().dateOfAdmission && (!dataCA.data().dateOfDischarge || dataCA.data().dateOfDischarge === 'N/A')) {
                statusLogicOnHold = dataCA.data().activityStatus === 'On Hold' ? 'Admitted (On Hold)' : 'Admitted'
            } else if(dataCA.data().dateOfDischarge && dataCA.data().dateOfDischarge !== 'N/A') {
                statusLogicOnHold = dataCA.data().activityStatus === 'On Hold' ? 'Discharged (On Hold)' : 'Discharged'
            } else {
                statusLogicOnHold = dataCA.data().activityStatus
            }
            // update statusAct client
            db.collection('clients').doc(dataCA.data().client).update({
              statusAct: 'Discharged',
              statusLogicOnHold: statusLogicOnHold
            })
          })
        }

        this._vm.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Client activity updated`,
            icon: 'CoffeeIcon',
            variant: 'success',
          },
        })
        
        if(!payload.data.dateOfAdmission && (!payload.data.dateOfDischarge || payload.data.dateOfDischarge === 'N/A')){
          db.collection('clientsActivity').doc(payload.id).update({
            activityStatusLogical: payload.data.activityStatus  
          })
          .catch(error => console.log(error))

          // update data client clientActivity
          db.collection('clientsActivity').doc(payload.id).get()
          .then(dataCA => {
            var statusLogicOnHold = ''
            if(dataCA.data().dateOfAdmission && dataCA.data().removeRecordDischargeData) {
                statusLogicOnHold = dataCA.data().activityStatus === 'On Hold' ? 'Admitted (On Hold)' : 'Admitted'
            } else if(dataCA.data().dateOfAdmission && (!dataCA.data().dateOfDischarge || dataCA.data().dateOfDischarge === 'N/A')) {
                statusLogicOnHold = dataCA.data().activityStatus === 'On Hold' ? 'Admitted (On Hold)' : 'Admitted'
            } else if(dataCA.data().dateOfDischarge && dataCA.data().dateOfDischarge !== 'N/A') {
                statusLogicOnHold = dataCA.data().activityStatus === 'On Hold' ? 'Discharged (On Hold)' : 'Discharged'
            } else {
                statusLogicOnHold = dataCA.data().activityStatus
            }

            // update statusAct client
            db.collection('clients').doc(dataCA.data().client).update({
              statusAct: payload.data.activityStatus,
              statusLogicOnHold: statusLogicOnHold
            })
          })
        }

        this._vm.$http.get(`https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/update-activity/relations/${payload.id}`)
        .then(res => {
          console.log('relations updated')
        })
        .catch(err => {
          console.log(err)
        })
      })
      .catch(error => console.log(error))
    },
    // register client claim
    createClientClaim({commit}, payload) {
      db.collection('clientsClaims').add(payload)
      .then(() => {
        this._vm.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Client claim registered`,
            icon: 'CoffeeIcon',
            variant: 'success',
          },
        })

        db.collection('usuarios').doc(payload.clinician).update({
          updated: Date.now()
        })
      })
      .catch(error => console.log(error))
    },
    // update client claim
    updateClientClaim({commit}, payload) {
      db.collection('clientsClaims').doc(payload.id).update(payload.data)
      .then(() => {
        this._vm.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Client claim updated`,
            icon: 'CoffeeIcon',
            variant: 'success',
          },
        })
      })
      .catch(error => console.log(error))
    },
    updateProblemGamblingServiceDelivery({commit}, payload) {
      db.collection('PGSD').doc('reporting').update(payload)
      .then(() => {
        this._vm.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Problem Gambling Service Delivery updated`,
            icon: 'CoffeeIcon',
            variant: 'success',
          },
        })
      })
      .catch(error => console.log(error))
    },
    cerrarSesion({commit}){
      // cerrar sesión
      // console.log('logout')
      auth.signOut()
      commit('nuevoUsuario',null)
      localStorage.removeItem('usAb')
      localStorage.removeItem('logActy')
      window.location.href = '/login'
    },

    // search clinician
    searchClinicianName({commit, state}, payload) {
      state.searchClinicianName = payload.toLowerCase()
      state.filterClinicianName = payload ? payload : ''
    },
    searchClinicianLocation({commit, state}, payload) {
      state.searchClinicianLocation = payload ? payload : ''
    },
    searchClinicianStatus({commit, state}, payload) {
      state.searchClinicianStatus = payload ? payload.toLowerCase() : ''
      state.filterClinicianStatus = payload ? payload : ''
    },
    searchClinicianInsuranceTypesAccepted({commit, state}, payload) {
      state.searchClinicianInsuranceTypesAccepted = payload ? payload : ''
      state.filterClinicianInsuranceTypesAccepted = payload ? payload : ''
    },
    searchClinicianLanguage({commit, state}, payload) {
      state.searchClinicianLanguage = payload ? payload : ''
      state.filterClinicianLanguage = payload ? payload : ''
    },
    searchClinicianRegion({commit, state}, payload) {
      state.searchClinicianRegion = payload ? payload.toLowerCase() : ''
      state.filterClinicianRegion = payload ? payload : ''
    },
    searchCountryOfResidence({commit, state}, payload) {
      state.searchCountryOfResidence = payload ? payload.toLowerCase() : ''
      state.filterCountryOfResidence = payload ? payload : ''
    },
    searchBorough({commit, state}, payload) {
      state.searchBorough = payload ? payload : ''
      state.filterBorough = payload ? payload : ''
    },
    searchProgram({commit, state}, payload) {
      state.searchProgram = payload ? payload : ''
      state.filterProgram = payload ? payload : ''
    },
    searchTypeOfService({commit, state}, payload) {
      state.searchTypeOfService = payload ? payload : ''
      state.filterTypeOfService = payload ? payload : ''
    },
    searchAcceptingNewClients({commit, state}, payload) {
      state.searchAcceptingNewClients = payload ? payload : ''
      state.filterAcceptingNewClients = payload ? payload : ''
    },
    searchAcceptReferralsOutsideOfCatchment({commit, state}, payload) {
      state.searchAcceptReferralsOutsideOfCatchment = payload ? payload : ''
      state.filterAcceptReferralsOutsideOfCatchment = payload ? payload : ''
    },
    setSnapshotClinician({commit,state},payload){
      // console.log(payload)
      commit('setClinician', payload)
    },

    // search client
    searchClientName({commit, state}, payload) {
      state.searchClientName = payload ? payload.toLowerCase() : ''
      state.searchClientNameState = payload ? payload.toLowerCase() : ''
    },
    searchClientID({commit, state}, payload) {
      state.searchClientID = payload ? payload.toLowerCase() : ''
      state.searchClientIDState = payload ? payload.toLowerCase() : ''
    },
    searchClientClinicianName({commit, state}, payload) {
      state.searchClientClinicianName = payload ? payload : ''
      state.searchClientClinicianNameState = payload ? payload : ''
    },
    searchClientClientCounty({commit, state}, payload) {
      state.searchClientClientCounty = payload ? payload.toLowerCase() : ''
      state.searchClientClientCountyState = payload ? payload.toLowerCase() : ''
    },
    searchClientClinicianRegion({commit, state}, payload) {
      state.searchClientClinicianRegion = payload ? payload : ''
      state.searchClientClinicianRegionState = payload ? payload : ''
    },
    searchClientClinicianBorough({commit, state}, payload) {
      state.searchClientClinicianBorough = payload ? payload : ''
      state.searchClientClinicianBoroughState = payload ? payload : ''
    },
    searchClientStatus({commit, state}, payload) {
      state.searchClientStatus = payload ? payload : ''
      state.searchClientStatusState = payload ? payload : ''
    },
    searchClientEmail({commit, state}, payload) {
      state.searchClientEmail = payload ? payload.toLowerCase() : ''
      state.searchClientEmailState = payload ? payload.toLowerCase() : ''
    },
    searchClientPhoneNumber({commit, state}, payload) {
      state.searchClientPhoneNumber = payload ? payload.toLowerCase() : ''
      state.searchClientPhoneNumberState = payload ? payload.toLowerCase() : ''
    },
    setSnapshotClient({commit,state}, payload){
      commit('setClient', payload)
    },

    // search claims
    searchClaimClinicianName({commit, state}, payload) {
      state.searchClaimClinicianName = payload.value ? payload.value : payload ? payload : ''
      state.searchClaimClinicianNameState = payload.value ? payload : payload ? payload : ''
    },
    searchClaimClientName({commit, state}, payload) {
      state.searchClaimClientName = payload ? payload.toLowerCase() : ''
      state.searchClaimNameState = payload ? payload.toLowerCase() : ''
    },
    searchClaimClaimStatus({commit, state}, payload) {
      state.searchClaimClaimStatus = payload ? payload.toLowerCase() : ''
      state.searchClaimStatusState = payload ? payload.toLowerCase() : ''
    },
    searchClaimClinicianRegion({commit, state}, payload) {
      state.searchClaimClinicianRegion = payload ? payload.toLowerCase() : ''
      state.searchClaimClinicianRegionState = payload ? payload.toLowerCase() : ''
    },
    searchClaimClinicianStatus({commit, state}, payload) {
      state.searchClaimClinicianStatus = payload ? payload.toLowerCase() : ''
      state.searchClaimClinicianStatusState = payload ? payload.toLowerCase() : ''
    },
    searchClaimStartDate({commit, state}, payload) {
      state.searchClaimStartDate = payload ? payload.toLowerCase() : ''
      state.searchClaimStartDateState = payload ? payload.toLowerCase() : ''
    },
    searchClaimEndDate({commit, state}, payload) {
      state.searchClaimEndDate = payload ? payload.toLowerCase() : ''
      state.searchClaimEndDateState = payload ? payload.toLowerCase() : ''
    },
    searchClaimsClientID({commit, state}, payload) {
      state.searchClaimsClientID = payload ? payload.toLowerCase() : ''
      state.searchClaimIDState = payload ? payload.toLowerCase() : ''
    },
    searchClaimClinicianProgram({commit, state}, payload) {
      state.searchClaimClinicianProgram = payload ? payload : ''
      state.searchClaimClinicianProgramState = payload ? payload : ''
    },
    setSnapshotClaims({commit,state}, payload){
      commit('setClaims', payload)
    },

    // search claims approved
    searchClaimApprovedClinicianName({commit, state}, payload) {
      state.searchClaimApprovedClinicianName = payload.value ? payload.value : payload ? payload : ''
      state.searchClaimApprovedClinicianNameState = payload.value ? payload : payload ? payload : ''
    },
    searchClaimApprovedClinicianRegion({commit, state}, payload) {
      state.searchClaimApprovedClinicianRegion = payload ? payload.toLowerCase() : ''
      state.searchClaimApprovedClinicianRegionState = payload ? payload.toLowerCase() : ''
    },
    searchClaimApprovedClinicianProgram({commit, state}, payload) {
      state.searchClaimApprovedClinicianProgram = payload ? payload : ''
      state.searchClaimApprovedClinicianProgramState = payload ? payload : ''
    },
    setSnapshotClaimsApproved({commit,state}, payload){
      commit('setClaimsApproved', payload)
    },

    // search claims users
    searchClaimUserName({commit, state}, payload) {
      state.searchClaimUserName = payload ? payload.toLowerCase() : ''
    },
    searchClaimUserStatus({commit, state}, payload) {
      state.searchClaimUserStatus = payload ? payload.toLowerCase() : ''
    },
    setSnapshotClaimsUser({commit,state}, payload){
      commit('setClaimsUser', payload)
    },

    // search client activity
    searchClientActivityName({commit, state}, payload) {
      state.searchClientActivityName = payload ? payload.toLowerCase() : ''
    },
    searchClientActivityStatus({commit, state}, payload) {
      state.searchClientActivityStatus = payload ? payload.toLowerCase() : ''
    },
    searchClientActivityClinicianName({commit, state}, payload) {
      state.searchClientActivityClinicianName = payload ? payload.toLowerCase() : ''
    },
    searchClientActivityClinicianRegion({commit, state}, payload) {
      state.searchClientActivityClinicianRegion = payload ? payload : ''
    },
    searchClientActivityClinicianStatus({commit, state}, payload) {
      state.searchClientActivityClinicianStatus = payload ? payload.toLowerCase() : ''
    },
    searchClientActivityClientID({commit, state}, payload) {
      state.searchClientActivityClientID = payload ? payload.toLowerCase() : ''
    },
    searchClientActivityStartDate({commit, state}, payload) {
      state.searchClientActivityStartDate = payload ? payload.toLowerCase() : ''
    },
    searchClientActivityEndDate({commit, state}, payload) {
      state.searchClientActivityEndDate = payload ? payload.toLowerCase() : ''
    },
    searchClientActivityClinicianProgram({commit, state}, payload) {
      state.searchClientActivityClinicianProgram = payload ? payload.toLowerCase() : ''
    },
    setSnapshotClientActivity({commit,state}, payload){
      commit('setClientActivity', payload)
    },

    // search client activity user
    searchClientActivityUserName({commit, state}, payload) {
      state.searchClientActivityUserName = payload ? payload.toLowerCase() : ''
    },
    searchClientActivityUserStatus({commit, state}, payload) {
      state.searchClientActivityUserStatus = payload ? payload.toLowerCase() : ''
    },
    setSnapshotClientActivityUser({commit,state}, payload){
      commit('setClientActivityUser', payload)
    },

    // search reports general
    setSnapshotReportsGeneral({commit,state}, payload){
      commit('setReportsGeneral', payload)
    },
    searchReportsGeneralStartDate({commit, state}, payload) {
      state.searchReportsGeneralStartDate = payload ? payload : ''
      state.searchReportsGeneralStartDateState = payload ? payload : ''
    },
    searchReportsGeneralEndDate({commit, state}, payload) {
      state.searchReportsGeneralEndDate = payload ? payload : ''
      state.searchReportsGeneralEndDateState = payload ? payload : ''
    },
    searchReportsGeneralClinicianName({commit, state}, payload) {
      state.searchReportsGeneralClinicianName = payload ? payload : ''
      state.searchReportsGeneralClinicianNameState = payload ? payload : ''
    },
    searchReportsGeneralClientName({commit, state}, payload) {
      state.searchReportsGeneralClientName = payload ? payload : ''
      state.searchReportsGeneralClientNameState = payload ? payload : ''
    },
    searchReportsGeneralnoIndividualSessions({commit, state}, payload) {
      state.searchReportsGeneralnoIndividualSessions = payload ? payload : ''
      state.searchReportsGeneralnoIndividualSessionsState = payload ? payload : ''
    },
    searchReportsGeneralnoGroupSessions({commit, state}, payload) {
      state.searchReportsGeneralnoGroupSessions = payload ? payload : ''
      state.searchReportsGeneralnoGroupSessionsState = payload ? payload : ''
    },
    searchReportsGeneralnoRemoteSessions({commit, state}, payload) {
      state.searchReportsGeneralnoRemoteSessions = payload ? payload : ''
      state.searchReportsGeneralnoRemoteSessionsState = payload ? payload : ''
    },
    searchReportsGeneralnoAssessmentSessions({commit, state}, payload) {
      state.searchReportsGeneralnoAssessmentSessions = payload ? payload : ''
      state.searchReportsGeneralnoAssessmentSessionsState = payload ? payload : ''
    },
    searchReportsGeneralnoCoupleSessions({commit, state}, payload) {
      state.searchReportsGeneralnoCoupleSessions = payload ? payload : ''
      state.searchReportsGeneralnoCoupleSessionsState = payload ? payload : ''
    },
    searchReportsGeneralnoFamilySessions({commit, state}, payload) {
      state.searchReportsGeneralnoFamilySessions = payload ? payload : ''
      state.searchReportsGeneralnoFamilySessionsState = payload ? payload : ''
    },
    searchReportsGeneralisInsuranceUtilizedForClaim({commit, state}, payload) {
      state.searchReportsGeneralisInsuranceUtilizedForClaim = payload ? payload : ''
      state.searchReportsGeneralisInsuranceUtilizedForClaimState = payload ? payload : ''
    },
    searchReportsGeneralclientProgressThisMonth({commit, state}, payload) {
      state.searchReportsGeneralclientProgressThisMonth = payload ? payload : ''
      state.searchReportsGeneralclientProgressThisMonthState = payload ? payload : ''
    },
    searchReportsGeneralclientReportsSatisfactionWithTreatmentSessions({commit, state}, payload) {
      state.searchReportsGeneralclientReportsSatisfactionWithTreatmentSessions = payload ? payload : ''
      state.searchReportsGeneralclientReportsSatisfactionWithTreatmentSessionsState = payload ? payload : ''
    },
    searchReportsGeneraldidClientProvideCopay({commit, state}, payload) {
      state.searchReportsGeneraldidClientProvideCopay = payload ? payload : ''
      state.searchReportsGeneraldidClientProvideCopayState = payload ? payload : ''
    },
    searchReportsGeneraldidClientUtilizeSlidingScale({commit, state}, payload) {
      state.searchReportsGeneraldidClientUtilizeSlidingScale = payload ? payload : ''
      state.searchReportsGeneraldidClientUtilizeSlidingScaleState = payload ? payload : ''
    },
    searchReportsGeneralRegion({commit, state}, payload) {
      state.searchReportsGeneralRegion = payload ? payload : ''
      state.searchReportsGeneralRegionState = payload ? payload : ''
    },
    searchReportsGeneralProgram({commit, state}, payload) {
      state.searchReportsGeneralProgram = payload ? payload : ''
      state.searchReportsGeneralProgramState = payload ? payload : ''
    },
    // searchReportsGeneralProgress({commit, state}, payload) {
    //   state.searchReportsGeneralProgress = payload ? payload.toLowerCase() : ''
    // },
    // searchReportsGeneralLanguage({commit, state}, payload) {
    //   state.searchReportsGeneralLanguage = payload ? payload.toLowerCase() : ''
    // },
    setSnapshotReportsGeneralClaims({commit,state}, payload){
      commit('setReportsGeneralClaims', payload)
    },
    setSnapshotReportsGeneralIndividualClientsSeen({commit,state}, payload){
      commit('setReportsGeneralIndividualClientsSeen', payload)
    },

    // search reports call data
    setSnapshotClientActivityReportsCallData({commit,state}, payload){
      commit('setReportsCallData', payload)
    },
    searchReportsCallDataStartDate({commit, state}, payload) {
      state.searchReportsCallDataStartDate = payload ? payload : ''
      state.searchReportsCallDataStartDateState = payload ? payload : ''
    },
    searchReportsCallDataEndDate({commit, state}, payload) {
      state.searchReportsCallDataEndDate = payload ? payload : ''
      state.searchReportsCallDataEndDateState = payload ? payload : ''
    },
    searchReportsCallDataRegion({commit, state}, payload) {
      state.searchReportsCallDataRegion = payload ? payload : ''
      state.searchReportsCallDataRegionState = payload ? payload : ''
    },
    searchReportsCallDataProgress({commit, state}, payload) {
      state.searchReportsCallDataProgress = payload ? payload : ''
      state.searchReportsCallDataProgressState = payload ? payload : ''
    },
    searchReportsCallDataLanguage({commit, state}, payload) {
      state.searchReportsCallDataLanguage = payload ? payload : ''
      state.searchReportsCallDataLanguageState = payload ? payload : ''
    },
    searchReportsCallDataInsurance({commit, state}, payload) {
      state.searchReportsCallDataInsurance = payload ? payload : ''
      state.searchReportsCallDataInsuranceState = payload ? payload : ''
    },
    searchReportsCallDataWarmTransfer({commit, state}, payload) {
      state.searchReportsCallDataWarmTransfer = payload ? payload : ''
      state.searchReportsCallDataWarmTransferState = payload ? payload : ''
    },
    searchReportsCallDataProgram({commit, state}, payload) {
      state.searchReportsCallDataProgram = payload ? payload.toLowerCase() : ''
      state.searchReportsCallDataProgramState = payload ? payload.toLowerCase() : ''
    },

    // search reports active treatment
    setSnapshotClientActivityReportsActiveTreatment({commit,state}, payload) {
      commit('setReportsActiveTreatment', payload)
    },
    setSnapshotClientClaimReportsActiveTreatment({commit,state}, payload) {
      commit('setReportsActiveTreatmentClaims', payload)
    },
    searchReportsActiveTreatmentStartDate({commit, state}, payload) {
      state.searchReportsActiveTreatmentStartDate = payload ? payload : ''
      state.searchReportsActiveTreatmentStartDateState = payload ? payload : ''
    },
    searchReportsActiveTreatmentEndDate({commit, state}, payload) {
      state.searchReportsActiveTreatmentEndDate = payload ? payload : ''
      state.searchReportsActiveTreatmentEndDateState = payload ? payload : ''
    },
    searchReportsActiveTreatmentRegion({commit, state}, payload) {
      state.searchReportsActiveTreatmentRegion = payload ? payload : ''
      state.searchReportsActiveTreatmentRegionState = payload ? payload : ''
    },
    searchReportsActiveTreatmentProgress({commit, state}, payload) {
      state.searchReportsActiveTreatmentProgress = payload ? payload : ''
      state.searchReportsActiveTreatmentProgressState = payload ? payload : ''
    },
    searchReportsActiveTreatmentLanguage({commit, state}, payload) {
      state.searchReportsActiveTreatmentLanguage = payload ? payload : ''
      state.searchReportsActiveTreatmentLanguageState = payload ? payload : ''
    },
    searchReportsActiveTreatmentInsurance({commit, state}, payload) {
      state.searchReportsActiveTreatmentInsurance = payload ? payload : ''
      state.searchReportsActiveTreatmentInsuranceState = payload ? payload : ''
    },
    searchReportsActiveTreatmentProgram({commit, state}, payload) {
      state.searchReportsActiveTreatmentProgram = payload ? payload.toLowerCase() : ''
      state.searchReportsActiveTreatmentProgramState = payload ? payload.toLowerCase() : ''
    },

    // search reports discharge data
    setSnapshotClientActivityReportsDischargeData({commit,state}, payload) {
      commit('setReportsDischargeData', payload)
    },
    searchReportsDischargeDataStartDate({commit, state}, payload) {
      state.searchReportsDischargeDataStartDate = payload ? payload : ''
      state.searchReportsDischargeDataStartDateState = payload ? payload : ''
    },
    searchReportsDischargeDataEndDate({commit, state}, payload) {
      state.searchReportsDischargeDataEndDate = payload ? payload : ''
      state.searchReportsDischargeDataEndDateState = payload ? payload : ''
    },
    searchReportsDischargeDataRegion({commit, state}, payload) {
      state.searchReportsDischargeDataRegion = payload ? payload : ''
      state.searchReportsDischargeDataRegionState = payload ? payload : ''
    },
    searchReportsDischargeDataProgress({commit, state}, payload) {
      state.searchReportsDischargeDataProgress = payload ? payload : ''
      state.searchReportsDischargeDataProgressState = payload ? payload : ''
    },
    searchReportsDischargeDataLanguage({commit, state}, payload) {
      state.searchReportsDischargeDataLanguage = payload ? payload : ''
      state.searchReportsDischargeDataLanguageState = payload ? payload : ''
    },
    searchReportsDischargeDataInsurance({commit, state}, payload) {
      state.searchReportsDischargeDataInsurance = payload ? payload : ''
      state.searchReportsDischargeDataInsuranceState = payload ? payload : ''
    },
    searchReportsDischargeDataProgram({commit, state}, payload) {
      state.searchReportsDischargeDataProgram = payload ? payload.toLowerCase() : ''
      state.searchReportsDischargeDataProgramState = payload ? payload.toLowerCase() : ''
    },

    // search reports insurance utilization
    setSnapshotClientActivityReportsInsuranceUtilization({commit,state}, payload) {
      commit('setReportsInsuranceUtilization', payload)
    },
    searchReportsInsuranceUtilizationStartDate({commit,state}, payload) {
      state.searchReportsInsuranceUtilizationStartDate = payload ? payload : ''
    },
    searchReportsInsuranceUtilizationEndDate({commit,state}, payload) {
      state.searchReportsInsuranceUtilizationEndDate = payload ? payload : ''
    },
    searchReportsInsuranceUtilizationClinicianName({commit,state}, payload) {
      state.searchReportsInsuranceUtilizationClinicianName = payload ? payload : ''
    },
    searchReportsInsuranceUtilizationClientName({commit,state}, payload) {
      state.searchReportsInsuranceUtilizationClientName = payload ? payload : ''
    },
    searchReportsInsuranceUtilizationRegion({commit,state}, payload) {
      state.searchReportsInsuranceUtilizationRegion = payload ? payload : ''
    },
    searchReportsInsuranceUtilizationProgram({commit,state}, payload) {
      state.searchReportsInsuranceUtilizationProgram = payload ? payload : ''
    },
    searchReportsInsuranceUtilizationIsClientInsured({commit,state}, payload) {
      state.searchReportsInsuranceUtilizationIsClientInsured = payload ? payload : ''
    },
    searchReportsInsuranceUtilizationIsClientMatchedToaClinicianWhoAcceptsTheirInsurance({commit,state}, payload) {
      state.searchReportsInsuranceUtilizationIsClientMatchedToaClinicianWhoAcceptsTheirInsurance = payload ? payload : ''
    },
    searchReportsInsuranceUtilizationIsTheClinicianBillingTheClientsInsurance({commit,state}, payload) {
      state.searchReportsInsuranceUtilizationIsTheClinicianBillingTheClientsInsurance = payload ? payload : ''
    },
    searchReportsInsuranceUtilizationWhyInsuranceWasNotBilled({commit,state}, payload) {
      state.searchReportsInsuranceUtilizationWhyInsuranceWasNotBilled = payload ? payload : ''
    },

    // search reports insurance utilization
    setSnapshotClientActivityReportsFinanceInformation({commit,state}, payload) {
      commit('setReportsFinanceInformation', payload)
    },
    searchReportsFinanceInformationStartDate({commit,state}, payload) {
      state.searchReportsFinanceInformationStartDate = payload ? payload : ''
    },
    searchReportsFinanceInformationEndDate({commit,state}, payload) {
      state.searchReportsFinanceInformationEndDate = payload ? payload : ''
    },
    searchReportsFinanceInformationClinicianName({commit,state}, payload) {
      state.searchReportsFinanceInformationClinicianName = payload ? payload : ''
    },
    searchReportsFinanceInformationClientName({commit,state}, payload) {
      state.searchReportsFinanceInformationClientName = payload ? payload : ''
    },
    searchReportsFinanceInformationRegion({commit,state}, payload) {
      state.searchReportsFinanceInformationRegion = payload ? payload : ''
    },
    searchReportsFinanceInformationProgram({commit,state}, payload) {
      state.searchReportsFinanceInformationProgram = payload ? payload : ''
    },
    searchReportsFinanceInformationInsuranceRates({commit,state}, payload) {
      state.searchReportsFinanceInformationInsuranceRates = payload ? payload : ''
    },
    searchReportsFinanceInformationDidClientProvideCopay({commit,state}, payload) {
      state.searchReportsFinanceInformationDidClientProvideCopay = payload ? payload : ''
    },
    searchReportsFinanceInformationDidclientUtilizeSlidingScale({commit,state}, payload) {
      state.searchReportsFinanceInformationDidclientUtilizeSlidingScale = payload ? payload : ''
    },

    // search reports clinician
    setSnapshotClientActivityReportsClinician({commit,state}, payload) {
      commit('setReportsClinician', payload)
    },
    searchReportsClinicianStartDate({commit,state}, payload) {
      state.searchReportsClinicianStartDate = payload ? payload : ''
    },
    searchReportsClinicianEndDate({commit,state}, payload) {
      state.searchReportsClinicianEndDate = payload ? payload : ''
    },
    searchReportsClinicianClinicianName({commit,state}, payload) {
      state.searchReportsClinicianClinicianName = payload ? payload : ''
    },
    searchReportsClinicianClientName({commit,state}, payload) {
      state.searchReportsClinicianClientName = payload ? payload : ''
    },
    searchReportsClinicianRegion({commit,state}, payload) {
      state.searchReportsClinicianRegion = payload ? payload : ''
    },
    searchReportsClinicianProgram({commit,state}, payload) {
      state.searchReportsClinicianProgram = payload ? payload : ''
    },
    searchReportsClinicianClaimDateMonth({commit,state}, payload) {
      state.searchReportsClinicianClaimDateMonth = payload ? payload : ''
    },
    searchReportsClinicianClaimDateYear({commit,state}, payload) {
      state.searchReportsClinicianClaimDateYear = payload ? payload : ''
    },
  },
  getters: {
    // return array: clinician search
    arrayFiltradoClinician(state) {
      if(!state.searchClinicianName) {
        state.searchClinicianName = ''
      }

      if(!state.searchClinicianLocation) {
        state.searchClinicianLocation = ''
      }

      if(!state.searchClinicianStatus) {
        state.searchClinicianStatus = ''
      }

      if(!state.searchClinicianInsuranceTypesAccepted) {
        state.searchClinicianInsuranceTypesAccepted = ''
      }

      if(!state.searchClinicianLanguage) {
        state.searchClinicianLanguage = ''
      }

      if(!state.searchClinicianRegion) {
        state.searchClinicianRegion = ''
      }

      if(!state.searchCountryOfResidence) {
        state.searchCountryOfResidence = ''
      }

      if(!state.searchBorough) {
        state.searchBorough = ''
      }

      if(!state.searchProgram) {
        state.searchProgram = ''
      }

      if(!state.searchTypeOfService) {
        state.searchTypeOfService = ''
      }

      if(!state.searchAcceptingNewClients) {
        state.searchAcceptingNewClients = ''
      }

      if(!state.searchAcceptReferralsOutsideOfCatchment) {
        state.searchAcceptReferralsOutsideOfCatchment = ''
      }

      let arregloFiltrado = []
      for(let clinician of state.clinicians) {
        // console.log(clinician.totalName)
        let name = clinician.totalName ? clinician.totalName.toLowerCase() : clinician.fullName.toLowerCase();
        let status = clinician.statusReferral.toLowerCase();
        let insuranceTypesAccepted = state.searchClinicianInsuranceTypesAccepted ? clinician.insuranceTypesAccepted : ''
        let languages = state.searchClinicianLanguage ? clinician.languages : ''
        let region = clinician.region.toLowerCase()
        let location = state.searchClinicianLocation ? clinician.region.toLowerCase() : ''
        let countryOfResidence = clinician.countryOfResidence ? clinician.countryOfResidence.toLowerCase() : ''
        let borough = clinician.borough ? clinician.borough : ''
        let mailingAddress = clinician.mailingAddress ? clinician.mailingAddress : ''
        let city = clinician.city ? clinician.city : ''
        let stateLoc = clinician.state ? clinician.state : ''
        let zip = clinician.zip ? clinician.zip : ''
        let program = state.searchProgram ? (clinician.program ? clinician.program : '') : ''
        let typeOfService = state.searchTypeOfService ? (clinician.typeOfService ? clinician.typeOfService : '') : ''
        let acceptingNewClients = state.searchAcceptingNewClients ? (clinician.acceptingNewClients ? clinician.acceptingNewClients : '') : ''
        let acceptReferralsOutsideOfCatchment = state.searchAcceptReferralsOutsideOfCatchment ? (clinician.acceptReferralsOutsideOfCatchment ? clinician.acceptReferralsOutsideOfCatchment : '') : ''
        
        let foundLocation = false

        if(state.searchClinicianLocation) {
          if(!foundLocation) {
            foundLocation = state.searchClinicianLocation.includes(mailingAddress)
          }

          if(!foundLocation) {
            foundLocation = state.searchClinicianLocation.includes(city)
          }

          if(!foundLocation) {
            foundLocation = state.searchClinicianLocation.includes(stateLoc)
          }

          if(!foundLocation) {
            foundLocation = state.searchClinicianLocation.includes(zip)
          }
        }
        
        if(!foundLocation) {
          if(clinician.referralDetail) {
            if(clinician.referralDetail.length >= 1) {
              clinician.referralDetail.forEach(item => {
                if(!foundLocation) {
                  if(!foundLocation) {
                    foundLocation = state.searchClinicianLocation.includes(item.state)
                  }

                  if(!foundLocation) {
                    foundLocation = state.searchClinicianLocation.includes(item.zip)
                  }

                  if(!foundLocation) {
                    foundLocation = state.searchClinicianLocation.includes(item.city)
                  }
                  
                  if(!foundLocation) {
                    foundLocation = state.searchClinicianLocation.includes(item.address)
                  }
                }
              })
            }
          }
        }
        // console.log(borough,state.searchBorough)257
        // state.searchClinicianLocation.includes(location)
        
        // console.log(state.searchProgram)
        if(state.searchClinicianLocation) {
          if(typeOfService.includes('Both') && state.searchTypeOfService) {
            if(name.indexOf(state.searchClinicianName) >= 0 && foundLocation && status.indexOf(state.searchClinicianStatus) === 0 && languages.includes(state.searchClinicianLanguage) && region.indexOf(state.searchClinicianRegion) === 0 && countryOfResidence.indexOf(state.searchCountryOfResidence) === 0 && borough.indexOf(state.searchBorough) === 0 && insuranceTypesAccepted.includes(state.searchClinicianInsuranceTypesAccepted) && program.indexOf(state.searchProgram) === 0 && acceptingNewClients.includes(state.searchAcceptingNewClients) && acceptReferralsOutsideOfCatchment.includes(state.searchAcceptReferralsOutsideOfCatchment)) {
              arregloFiltrado.push(clinician)
            }
          } else {
            if(name.indexOf(state.searchClinicianName) >= 0 && foundLocation && status.indexOf(state.searchClinicianStatus) === 0 && languages.includes(state.searchClinicianLanguage) && region.indexOf(state.searchClinicianRegion) === 0 && countryOfResidence.indexOf(state.searchCountryOfResidence) === 0 && borough.indexOf(state.searchBorough) === 0 && insuranceTypesAccepted.includes(state.searchClinicianInsuranceTypesAccepted) && program.indexOf(state.searchProgram) === 0 && typeOfService.includes(state.searchTypeOfService) && acceptingNewClients.includes(state.searchAcceptingNewClients) && acceptReferralsOutsideOfCatchment.includes(state.searchAcceptReferralsOutsideOfCatchment)) {
              arregloFiltrado.push(clinician)
            }
          }
        } else {
          if(typeOfService.includes('Both') && state.searchTypeOfService) {
            if(name.indexOf(state.searchClinicianName) >= 0 && state.searchClinicianLocation.includes(location) && status.indexOf(state.searchClinicianStatus) === 0 && languages.includes(state.searchClinicianLanguage) && region.indexOf(state.searchClinicianRegion) === 0 && countryOfResidence.indexOf(state.searchCountryOfResidence) === 0 && borough.indexOf(state.searchBorough) === 0 && insuranceTypesAccepted.includes(state.searchClinicianInsuranceTypesAccepted) && program.indexOf(state.searchProgram) === 0 && acceptingNewClients.includes(state.searchAcceptingNewClients) && acceptReferralsOutsideOfCatchment.includes(state.searchAcceptReferralsOutsideOfCatchment)) {
              arregloFiltrado.push(clinician)
            }
          } else {
            if(name.indexOf(state.searchClinicianName) >= 0 && state.searchClinicianLocation.includes(location) && status.indexOf(state.searchClinicianStatus) === 0 && languages.includes(state.searchClinicianLanguage) && region.indexOf(state.searchClinicianRegion) === 0 && countryOfResidence.indexOf(state.searchCountryOfResidence) === 0 && borough.indexOf(state.searchBorough) === 0 && insuranceTypesAccepted.includes(state.searchClinicianInsuranceTypesAccepted) && program.indexOf(state.searchProgram) === 0 && typeOfService.includes(state.searchTypeOfService) && acceptingNewClients.includes(state.searchAcceptingNewClients) && acceptReferralsOutsideOfCatchment.includes(state.searchAcceptReferralsOutsideOfCatchment)) {
              arregloFiltrado.push(clinician)
            }
          }
        }
      }
      return arregloFiltrado
    },
    // return array: client search
    arrayFiltradoClient(state) {
      if(!state.searchClientName) {
        state.searchClientName = ''
      }

      if(!state.searchClientID) {
        state.searchClientID = ''
      }

      if(!state.searchClientClinicianName) {
        state.searchClientClinicianName = ''
      }

      if(!state.searchClientClientCounty) {
        state.searchClientClientCounty = ''
      }

      if(!state.searchClientClinicianRegion) {
        state.searchClientClinicianRegion = ''
      }

      if(!state.searchClientClinicianBorough) {
        state.searchClientClinicianBorough = ''
      }

      if(!state.searchClientStatus) {
        state.searchClientStatus = ''
      }

      if(!state.searchClientEmail) {
        state.searchClientEmail = ''
      }

      if(!state.searchClientPhoneNumber) {
        state.searchClientPhoneNumber = ''
      }

      let arregloFiltrado = []
      for (let client of state.clients) {
        let clientName = client.name.toLowerCase();
        // let clientID = client.clientID ? '#' + parseInt(client.clientID.replace('#', ''), 10).toString() : '';
        let clientID = client.clientID ? client.clientID : '';
        // console.log(clientID)
        // client.clientID.toLowerCase()
        let clientClinicianName = state.searchClientClinicianName ? client.clientClinicianName : ''
        let clientClientCounty = client.clientClientCounty.toLowerCase()
        let clientClinicianRegion = state.searchClientClinicianRegion ? client.clientClinicianRegion : ''
        // let clientClinicianRegion = state.searchClientClinicianRegion ? client.clRegion : ''
        let clientClinicianBorough = state.searchClientClinicianBorough ? client.clientClinicianBorough : ''
        let clientStatus = client.clientStatus.toLowerCase()
        let clientEmail = client.email.toLowerCase()
        let clientPhone = client.phone.toLowerCase()
        let activitiesClinician = client.activitiesClinician ? client.activitiesClinician : []

        var arrayClientStatus = client.arrayStatus ? client.arrayStatus : []
        if(state.searchClientStatus) {
          // activitiesClinician.forEach(ele => {
          //   if(ele.statusActivity) {
          //     arrayClientStatus.push(ele.statusActivity)
          //   }
          // })

          if(state.searchClientClinicianName) {
            var arrayclientClinicianName = []
            activitiesClinician.forEach(ele => {
              ele.clinicians.forEach(item => {
                arrayclientClinicianName.push(item.name)

                if(state.searchClientClinicianName === item.name) {
                  // console.log(state.searchClientClinicianName,item.name,ele.statusActivity)
                  ele.statusActivity ? client.status = ele.statusActivity : null
                }
              })
            })

            if(state.searchClientClinicianRegion === 'NYC' && !state.searchClientClinicianBorough) {
              // clientClinicianBorough ? clientClinicianRegion = clientClinicianBorough : null
              if((clientClinicianRegion.includes(state.searchClientClinicianRegion) || clientClinicianRegion.includes('Bronx') || clientClinicianRegion.includes('Brooklyn') || clientClinicianRegion.includes('Manhattan') || clientClinicianRegion.includes('Queens') || clientClinicianRegion.includes('Staten Island')) && clientName.indexOf(state.searchClientName) >= 0 && clientID.indexOf(state.searchClientID) === 0 && arrayclientClinicianName.includes(state.searchClientClinicianName) && clientClientCounty.indexOf(state.searchClientClientCounty) === 0 && clientClinicianBorough.includes(state.searchClientClinicianBorough) && arrayClientStatus.includes(state.searchClientStatus) && clientEmail.indexOf(state.searchClientEmail) === 0 && clientPhone.indexOf(state.searchClientPhoneNumber) === 0) {
                // activitiesClinician
                arregloFiltrado.push(client)
              }
            } else {
              if(state.searchClientClinicianBorough) {
                if(clientName.indexOf(state.searchClientName) >= 0 && clientID.indexOf(state.searchClientID) === 0 && arrayclientClinicianName.includes(state.searchClientClinicianName) && clientClientCounty.indexOf(state.searchClientClientCounty) === 0 && (clientClinicianRegion.includes(state.searchClientClinicianRegion) || clientClinicianRegion.includes(state.searchClientClinicianBorough) || clientClinicianBorough.includes(state.searchClientClinicianBorough)) && arrayClientStatus.includes(state.searchClientStatus) && clientEmail.indexOf(state.searchClientEmail) === 0 && clientPhone.indexOf(state.searchClientPhoneNumber) === 0) {
                  // activitiesClinician
                  arregloFiltrado.push(client)
                }
              } else {
                if(clientName.indexOf(state.searchClientName) >= 0 && clientID.indexOf(state.searchClientID) === 0 && arrayclientClinicianName.includes(state.searchClientClinicianName) && clientClientCounty.indexOf(state.searchClientClientCounty) === 0 && clientClinicianRegion.includes(state.searchClientClinicianRegion) && clientClinicianBorough.includes(state.searchClientClinicianBorough) && arrayClientStatus.includes(state.searchClientStatus) && clientEmail.indexOf(state.searchClientEmail) === 0 && clientPhone.indexOf(state.searchClientPhoneNumber) === 0) {
                  // activitiesClinician
                  arregloFiltrado.push(client)
                }
              }
            }
          } else {
            if(state.searchClientClinicianRegion === 'NYC' && !state.searchClientClinicianBorough) {
              // clientClinicianBorough ? clientClinicianRegion = clientClinicianBorough : null
              if((clientClinicianRegion.includes(state.searchClientClinicianRegion) || clientClinicianRegion.includes('Bronx') || clientClinicianRegion.includes('Brooklyn') || clientClinicianRegion.includes('Manhattan') || clientClinicianRegion.includes('Queens') || clientClinicianRegion.includes('Staten Island')) && clientName.indexOf(state.searchClientName) >= 0 && clientID.indexOf(state.searchClientID) === 0 && clientClinicianName.includes(state.searchClientClinicianName) && clientClientCounty.indexOf(state.searchClientClientCounty) === 0 && clientClinicianBorough.includes(state.searchClientClinicianBorough) && arrayClientStatus.includes(state.searchClientStatus) && clientEmail.indexOf(state.searchClientEmail) === 0 && clientPhone.indexOf(state.searchClientPhoneNumber) === 0) {
                // activitiesClinician
                arregloFiltrado.push(client)
              }
            } else {
              if(state.searchClientClinicianBorough) {
                if(clientName.indexOf(state.searchClientName) >= 0 && clientID.indexOf(state.searchClientID) === 0 && clientClinicianName.includes(state.searchClientClinicianName) && clientClientCounty.indexOf(state.searchClientClientCounty) === 0 && (clientClinicianRegion.includes(state.searchClientClinicianRegion) || clientClinicianRegion.includes(state.searchClientClinicianBorough) || clientClinicianBorough.includes(state.searchClientClinicianBorough)) && arrayClientStatus.includes(state.searchClientStatus) && clientEmail.indexOf(state.searchClientEmail) === 0 && clientPhone.indexOf(state.searchClientPhoneNumber) === 0) {
                  // activitiesClinician
                  arregloFiltrado.push(client)
                }
              } else {
                if(clientName.indexOf(state.searchClientName) >= 0 && clientID.indexOf(state.searchClientID) === 0 && clientClinicianName.includes(state.searchClientClinicianName) && clientClientCounty.indexOf(state.searchClientClientCounty) === 0 && clientClinicianRegion.includes(state.searchClientClinicianRegion) && clientClinicianBorough.includes(state.searchClientClinicianBorough) && arrayClientStatus.includes(state.searchClientStatus) && clientEmail.indexOf(state.searchClientEmail) === 0 && clientPhone.indexOf(state.searchClientPhoneNumber) === 0) {
                  // activitiesClinician
                  arregloFiltrado.push(client)
                }
              }
            }
          }
        } else {
          if(state.searchClientClinicianName) {
            var arrayclientClinicianName = []
            activitiesClinician.forEach(ele => {
              ele.clinicians.forEach(item => {
                arrayclientClinicianName.push(item.name)
  
                if(state.searchClientClinicianName === item.name) {
                  // console.log(state.searchClientClinicianName,item.name,ele.statusActivity)
                  ele.statusActivity ? client.status = ele.statusActivity : null
                }
              })
            })
  
            if(state.searchClientClinicianRegion === 'NYC' && !state.searchClientClinicianBorough) {
              // clientClinicianBorough ? clientClinicianRegion = clientClinicianBorough : null
              if((clientClinicianRegion.includes(state.searchClientClinicianRegion) || clientClinicianRegion.includes('Bronx') || clientClinicianRegion.includes('Brooklyn') || clientClinicianRegion.includes('Manhattan') || clientClinicianRegion.includes('Queens') || clientClinicianRegion.includes('Staten Island')) && clientName.indexOf(state.searchClientName) >= 0 && clientID.indexOf(state.searchClientID) === 0 && arrayclientClinicianName.includes(state.searchClientClinicianName) && clientClientCounty.indexOf(state.searchClientClientCounty) === 0 && clientClinicianBorough.includes(state.searchClientClinicianBorough) && clientStatus.indexOf(state.searchClientStatus) === 0 && clientEmail.indexOf(state.searchClientEmail) === 0 && clientPhone.indexOf(state.searchClientPhoneNumber) === 0) {
                // activitiesClinician
                arregloFiltrado.push(client)
              }
            } else {
              if(state.searchClientClinicianBorough) {
                if(clientName.indexOf(state.searchClientName) >= 0 && clientID.indexOf(state.searchClientID) === 0 && arrayclientClinicianName.includes(state.searchClientClinicianName) && clientClientCounty.indexOf(state.searchClientClientCounty) === 0 && (clientClinicianRegion.includes(state.searchClientClinicianRegion) || clientClinicianRegion.includes(state.searchClientClinicianBorough) || clientClinicianBorough.includes(state.searchClientClinicianBorough)) && clientStatus.indexOf(state.searchClientStatus) === 0 && clientEmail.indexOf(state.searchClientEmail) === 0 && clientPhone.indexOf(state.searchClientPhoneNumber) === 0) {
                  // activitiesClinician
                  arregloFiltrado.push(client)
                }
              } else {
                if(clientName.indexOf(state.searchClientName) >= 0 && clientID.indexOf(state.searchClientID) === 0 && arrayclientClinicianName.includes(state.searchClientClinicianName) && clientClientCounty.indexOf(state.searchClientClientCounty) === 0 && clientClinicianRegion.includes(state.searchClientClinicianRegion) && clientClinicianBorough.includes(state.searchClientClinicianBorough) && clientStatus.indexOf(state.searchClientStatus) === 0 && clientEmail.indexOf(state.searchClientEmail) === 0 && clientPhone.indexOf(state.searchClientPhoneNumber) === 0) {
                  // activitiesClinician
                  arregloFiltrado.push(client)
                }
              }
            }
          } else {
            if(state.searchClientClinicianRegion === 'NYC' && !state.searchClientClinicianBorough) {
              // clientClinicianBorough ? clientClinicianRegion = clientClinicianBorough : null
              if((clientClinicianRegion.includes(state.searchClientClinicianRegion) || clientClinicianRegion.includes('Bronx') || clientClinicianRegion.includes('Brooklyn') || clientClinicianRegion.includes('Manhattan') || clientClinicianRegion.includes('Queens') || clientClinicianRegion.includes('Staten Island')) && clientName.indexOf(state.searchClientName) >= 0 && clientID.indexOf(state.searchClientID) === 0 && clientClinicianName.includes(state.searchClientClinicianName) && clientClientCounty.indexOf(state.searchClientClientCounty) === 0 && clientClinicianBorough.includes(state.searchClientClinicianBorough) && clientStatus.indexOf(state.searchClientStatus) === 0 && clientEmail.indexOf(state.searchClientEmail) === 0 && clientPhone.indexOf(state.searchClientPhoneNumber) === 0) {
                // activitiesClinician
                arregloFiltrado.push(client)
              }
            } else {
              if(state.searchClientClinicianBorough) {
                if(clientName.indexOf(state.searchClientName) >= 0 && clientID.indexOf(state.searchClientID) === 0 && clientClinicianName.includes(state.searchClientClinicianName) && clientClientCounty.indexOf(state.searchClientClientCounty) === 0 && (clientClinicianRegion.includes(state.searchClientClinicianRegion) || clientClinicianRegion.includes(state.searchClientClinicianBorough) || clientClinicianBorough.includes(state.searchClientClinicianBorough)) && clientStatus.indexOf(state.searchClientStatus) === 0 && clientEmail.indexOf(state.searchClientEmail) === 0 && clientPhone.indexOf(state.searchClientPhoneNumber) === 0) {
                  // activitiesClinician
                  arregloFiltrado.push(client)
                }
              } else {
                if(clientName.indexOf(state.searchClientName) >= 0 && clientID.indexOf(state.searchClientID) === 0 && clientClinicianName.includes(state.searchClientClinicianName) && clientClientCounty.indexOf(state.searchClientClientCounty) === 0 && clientClinicianRegion.includes(state.searchClientClinicianRegion) && clientClinicianBorough.includes(state.searchClientClinicianBorough) && clientStatus.indexOf(state.searchClientStatus) === 0 && clientEmail.indexOf(state.searchClientEmail) === 0 && clientPhone.indexOf(state.searchClientPhoneNumber) === 0) {
                  // activitiesClinician
                  arregloFiltrado.push(client)
                }
              }
            }
          }
        }
      }
      return arregloFiltrado
    },
    // return array: all claims
    arrayFiltradoClaims(state) {
      if(!state.searchClaimClinicianName) {
        state.searchClaimClinicianName = ''
      }
      if(!state.searchClaimClientName) {
        state.searchClaimClientName = ''
      }
      if(!state.searchClaimClaimStatus) {
        state.searchClaimClaimStatus = ''
      }
      if(!state.searchClaimClinicianRegion) {
        state.searchClaimClinicianRegion = ''
      }
      if(!state.searchClaimClinicianStatus) {
        state.searchClaimClinicianStatus = ''
      }
      if(!state.searchClaimStartDate) {
        state.searchClaimStartDate = ''
      }
      if(!state.searchClaimEndDate) {
        state.searchClaimEndDate = ''
      }
      if(!state.searchClaimsClientID) {
        state.searchClaimsClientID = ''
      }
      if(!state.searchClaimClinicianProgram) {
        state.searchClaimClinicianProgram = ''
      }

      let arregloFiltrado = []
      for (let claim of state.claims) {
        let clinicianName = claim.clinicianName ? claim.clinicianName : ''
        let clientName = claim.clientName ? claim.clientName.toLowerCase() : ''
        let approvalStatus = claim.approvalStatus ? claim.approvalStatus.toLowerCase() : ''
        let region = claim.region ? claim.region.toLowerCase() : ''
        let regionBorough = claim.regionBorough ? claim.regionBorough.toLowerCase() : 'none'
        let clinicianStatus = claim.clinicianStatus ? claim.clinicianStatus.toLowerCase() : ''
        // let clientID = claim.clientID ? claim.clientID.toLowerCase() : ''
        // let clientID = claim.clientID ? '#' + parseInt(claim.clientID.replace('#', ''), 10).toString() : '';
        let clientID = claim.clientID ? claim.clientID : '';
        let claimDateFormat = claim.claimDateFormat ? claim.claimDateFormat.toLowerCase() : ''
        let clinicianProgram = state.searchClaimClinicianProgram ? (claim.clinicianProgram ? claim.clinicianProgram : '') : ''

        var dateFrom = state.searchClaimStartDate.split('/')
        var dateTo = state.searchClaimEndDate.split('/')
        var dateCheck = claimDateFormat.split('/')

        var from = new Date(dateFrom[2], parseInt(dateFrom[1])-1, dateFrom[0])
        var to   = new Date(dateTo[2], parseInt(dateTo[1])-1, dateTo[0])
        var check = new Date(dateCheck[2], parseInt(dateCheck[1])-1, dateCheck[0])

        if(!state.searchClaimStartDate || !state.searchClaimEndDate) {
          if(state.searchClaimClinicianName) {
            if(state.searchClaimClinicianName.includes(clinicianName) && clientName.indexOf(state.searchClaimClientName) === 0 && approvalStatus.indexOf(state.searchClaimClaimStatus) === 0 && (state.searchClaimClinicianRegion === 'bronx' || state.searchClaimClinicianRegion === 'brooklyn' || state.searchClaimClinicianRegion === 'manhattan' || state.searchClaimClinicianRegion === 'queens' || state.searchClaimClinicianRegion === 'staten island' ? regionBorough.indexOf(state.searchClaimClinicianRegion) === 0 : region.indexOf(state.searchClaimClinicianRegion) === 0) && clinicianStatus.indexOf(state.searchClaimClinicianStatus) === 0 && clientID.indexOf(state.searchClaimsClientID) === 0 && clinicianProgram.includes(state.searchClaimClinicianProgram)) {
              arregloFiltrado.push(claim)
            }
          } else {
            if(clinicianName.indexOf(state.searchClaimClinicianName) === 0 && clientName.indexOf(state.searchClaimClientName) === 0 && approvalStatus.indexOf(state.searchClaimClaimStatus) === 0 && (state.searchClaimClinicianRegion === 'bronx' || state.searchClaimClinicianRegion === 'brooklyn' || state.searchClaimClinicianRegion === 'manhattan' || state.searchClaimClinicianRegion === 'queens' || state.searchClaimClinicianRegion === 'staten island' ? regionBorough.indexOf(state.searchClaimClinicianRegion) === 0 : region.indexOf(state.searchClaimClinicianRegion) === 0) && clinicianStatus.indexOf(state.searchClaimClinicianStatus) === 0 && clientID.indexOf(state.searchClaimsClientID) === 0 && clinicianProgram.includes(state.searchClaimClinicianProgram)) {
              arregloFiltrado.push(claim)
            }
          }
        } else if(check >= from && check <= to) {
          if(state.searchClaimClinicianName) {
            if(state.searchClaimClinicianName.includes(clinicianName) && clientName.indexOf(state.searchClaimClientName) === 0 && approvalStatus.indexOf(state.searchClaimClaimStatus) === 0 && (state.searchClaimClinicianRegion === 'bronx' || state.searchClaimClinicianRegion === 'brooklyn' || state.searchClaimClinicianRegion === 'manhattan' || state.searchClaimClinicianRegion === 'queens' || state.searchClaimClinicianRegion === 'staten island' ? regionBorough.indexOf(state.searchClaimClinicianRegion) === 0 : region.indexOf(state.searchClaimClinicianRegion) === 0) && clinicianStatus.indexOf(state.searchClaimClinicianStatus) === 0 && clientID.indexOf(state.searchClaimsClientID) === 0 && clinicianProgram.includes(state.searchClaimClinicianProgram)) {
              arregloFiltrado.push(claim)
            }
          } else {
            if(clinicianName.indexOf(state.searchClaimClinicianName) === 0 && clientName.indexOf(state.searchClaimClientName) === 0 && approvalStatus.indexOf(state.searchClaimClaimStatus) === 0 && (state.searchClaimClinicianRegion === 'bronx' || state.searchClaimClinicianRegion === 'brooklyn' || state.searchClaimClinicianRegion === 'manhattan' || state.searchClaimClinicianRegion === 'queens' || state.searchClaimClinicianRegion === 'staten island' ? regionBorough.indexOf(state.searchClaimClinicianRegion) === 0 : region.indexOf(state.searchClaimClinicianRegion) === 0) && clinicianStatus.indexOf(state.searchClaimClinicianStatus) === 0 && clientID.indexOf(state.searchClaimsClientID) === 0 && clinicianProgram.includes(state.searchClaimClinicianProgram)) {
              arregloFiltrado.push(claim)
            }
          }
        }
      }
      return arregloFiltrado
    },
    // return array: all claims approved
    arrayFiltradoClaimsApproved(state) {
      if(!state.searchClaimApprovedClinicianName) {
        state.searchClaimApprovedClinicianName = ''
      }
      if(!state.searchClaimApprovedClinicianRegion) {
        state.searchClaimApprovedClinicianRegion = ''
      }
      if(!state.searchClaimApprovedClinicianProgram) {
        state.searchClaimApprovedClinicianProgram = ''
      }

      let arregloFiltrado = []
      for (let claim of state.claimsApproved) {
        let clinicianName = claim.clinicianName ? claim.clinicianName : ''
        let clientName = claim.clientName ? claim.clientName.toLowerCase() : ''
        let approvalStatus = claim.approvalStatus ? claim.approvalStatus.toLowerCase() : ''
        let region = claim.region ? claim.region.toLowerCase() : ''
        let regionBorough = claim.regionBorough ? claim.regionBorough.toLowerCase() : 'none'
        let clinicianStatus = claim.clinicianStatus ? claim.clinicianStatus.toLowerCase() : ''
        // let clientID = claim.clientID ? claim.clientID.toLowerCase() : ''
        // let clientID = claim.clientID ? '#' + parseInt(claim.clientID.replace('#', ''), 10).toString() : '';
        let clientID = claim.clientID ? claim.clientID : '';
        let claimDateFormat = claim.claimDateFormat ? claim.claimDateFormat.toLowerCase() : ''
        let clinicianProgram = state.searchClaimApprovedClinicianProgram ? (claim.clinicianProgram ? claim.clinicianProgram : '') : ''

        var dateFrom = state.searchClaimStartDate.split('/')
        var dateTo = state.searchClaimEndDate.split('/')
        var dateCheck = claimDateFormat.split('/')

        var from = new Date(dateFrom[2], parseInt(dateFrom[1])-1, dateFrom[0])
        var to   = new Date(dateTo[2], parseInt(dateTo[1])-1, dateTo[0])
        var check = new Date(dateCheck[2], parseInt(dateCheck[1])-1, dateCheck[0])

        if(!state.searchClaimStartDate || !state.searchClaimEndDate) {
          if(state.searchClaimApprovedClinicianName) {
            if(state.searchClaimApprovedClinicianName.includes(clinicianName) && (state.searchClaimApprovedClinicianRegion === 'bronx' || state.searchClaimApprovedClinicianRegion === 'brooklyn' || state.searchClaimApprovedClinicianRegion === 'manhattan' || state.searchClaimApprovedClinicianRegion === 'queens' || state.searchClaimApprovedClinicianRegion === 'staten island' ? regionBorough.indexOf(state.searchClaimApprovedClinicianRegion) === 0 : region.indexOf(state.searchClaimApprovedClinicianRegion) === 0) && clinicianProgram.includes(state.searchClaimApprovedClinicianProgram)) {
              arregloFiltrado.push(claim)
            }
          } else {
            if(clinicianName.indexOf(state.searchClaimApprovedClinicianName) === 0 && (state.searchClaimApprovedClinicianRegion === 'bronx' || state.searchClaimApprovedClinicianRegion === 'brooklyn' || state.searchClaimApprovedClinicianRegion === 'manhattan' || state.searchClaimApprovedClinicianRegion === 'queens' || state.searchClaimApprovedClinicianRegion === 'staten island' ? regionBorough.indexOf(state.searchClaimApprovedClinicianRegion) === 0 : region.indexOf(state.searchClaimApprovedClinicianRegion) === 0) && clinicianProgram.includes(state.searchClaimApprovedClinicianProgram)) {
              arregloFiltrado.push(claim)
            }
          }
        } else if(check >= from && check <= to) {
          if(state.searchClaimApprovedClinicianName) {
            if(state.searchClaimApprovedClinicianName.includes(clinicianName) && (state.searchClaimApprovedClinicianRegion === 'bronx' || state.searchClaimApprovedClinicianRegion === 'brooklyn' || state.searchClaimApprovedClinicianRegion === 'manhattan' || state.searchClaimApprovedClinicianRegion === 'queens' || state.searchClaimApprovedClinicianRegion === 'staten island' ? regionBorough.indexOf(state.searchClaimApprovedClinicianRegion) === 0 : region.indexOf(state.searchClaimApprovedClinicianRegion) === 0) && clinicianProgram.includes(state.searchClaimApprovedClinicianProgram)) {
              arregloFiltrado.push(claim)
            }
          } else {
            if(clinicianName.indexOf(state.searchClaimApprovedClinicianName) === 0 && (state.searchClaimApprovedClinicianRegion === 'bronx' || state.searchClaimApprovedClinicianRegion === 'brooklyn' || state.searchClaimApprovedClinicianRegion === 'manhattan' || state.searchClaimApprovedClinicianRegion === 'queens' || state.searchClaimApprovedClinicianRegion === 'staten island' ? regionBorough.indexOf(state.searchClaimApprovedClinicianRegion) === 0 : region.indexOf(state.searchClaimApprovedClinicianRegion) === 0) && clinicianProgram.includes(state.searchClaimApprovedClinicianProgram)) {
              arregloFiltrado.push(claim)
            }
          }
        }
      }
      return arregloFiltrado
    },
    // return array: claims user
    arrayFiltradoClaimsUser(state) {
      if(!state.searchClaimUserName) {
        state.searchClaimUserName = ''
      }

      if(!state.searchClaimUserStatus) {
        state.searchClaimUserStatus = ''
      }
      
      let arregloFiltrado = []
      for (let claim of state.claimsUser) {
        // let clientID = claim.clientID ? claim.clientID.toString().toLowerCase() : '';
        // let clientID = claim.clientID ? parseInt(claim.clientID.replace('#', ''), 10).toString() : '';
        let clientID = claim.clientID ? claim.clientID : '';
        let clientName = claim.clientName ? claim.clientName.toLowerCase() : '';
        let approvalStatus = claim.approvalStatus.toLowerCase();
        //  || clientID.indexOf(state.searchClaimUserName) === 0
        if(state.searchClaimUserStatus) {
          if(approvalStatus.indexOf(state.searchClaimUserStatus) === 0) {
            if(clientName.indexOf(state.searchClaimUserName) === 0 || clientID.indexOf(state.searchClaimUserName) === 0) {
              arregloFiltrado.push(claim)
            }
          }
        } else {
          if(clientName.indexOf(state.searchClaimUserName) === 0 || clientID.indexOf(state.searchClaimUserName) === 0) {
            arregloFiltrado.push(claim)
          }
        }
        
      }
      return arregloFiltrado
    },
    // return array: client activity user search
    arrayFiltradoClientActivityUser(state) {
      if(!state.searchClientActivityUserName) {
        state.searchClientActivityUserName = ''
      }

      if(!state.searchClientActivityUserStatus) {
        state.searchClientActivityUserStatus = ''
      }
      
      let arregloFiltrado = []
      for (let clientsActivity of state.clientsActivityUser) {
        // let clientID = clientsActivity.clientID ? clientsActivity.clientID.toString().toLowerCase() : '';
        // let clientID = clientsActivity.clientID ? parseInt(clientsActivity.clientID.replace('#', ''), 10).toString() : '';
        let clientID = clientsActivity.clientID ? clientsActivity.clientID : '';
        let clientName = clientsActivity.clientName ? clientsActivity.clientName.toLowerCase() : '';
        let status = clientsActivity.status.toLowerCase();

        if(state.searchClientActivityUserStatus) {
          if(status.indexOf(state.searchClientActivityUserStatus) === 0) {
            if(clientName.indexOf(state.searchClientActivityUserName) >= 0 || clientID.indexOf(state.searchClientActivityUserName) === 0) {
              arregloFiltrado.push(clientsActivity)
            }
          }
        } else {
          if(clientName.indexOf(state.searchClientActivityUserName) >= 0 || clientID.indexOf(state.searchClientActivityUserName) === 0) {
            arregloFiltrado.push(clientsActivity)
          }
        }
      }
      return arregloFiltrado
    },
    // return array: client activity search
    arrayFiltradoClientActivity(state) {
      if(!state.searchClientActivityName) {
        state.searchClientActivityName = ''
      }

      if(!state.searchClientActivityStatus) {
        state.searchClientActivityStatus = ''
      }

      if(!state.searchClientActivityClinicianName) {
        state.searchClientActivityClinicianName = ''
      }

      if(!state.searchClientActivityClinicianRegion) {
        state.searchClientActivityClinicianRegion = ''
      }

      if(!state.searchClientActivityClinicianStatus) {
        state.searchClientActivityClinicianStatus = ''
      }

      if(!state.searchClientActivityClientID) {
        state.searchClientActivityClientID = ''
      }

      if(!state.searchClientActivityStartDate) {
        state.searchClientActivityStartDate = ''
      }

      if(!state.searchClientActivityEndDate) {
        state.searchClientActivityEndDate = ''
      }

      if(!state.searchClientActivityClinicianProgram) {
        state.searchClientActivityClinicianProgram = ''
      }

      let arregloFiltrado = []
      for (let client of state.clientsActivity) {
        let clientName = client.clientName.toLowerCase();
        let clinicianName = client.clinicianName ? client.clinicianName.toLowerCase() : '';
        let region = client.borough ? client.borough : client.region ? client.region : '';
        // let region = state.searchClientActivityClinicianRegion ? client.clinicianRegion : ''
        let clinicianStatus = client.clinicianStatus ? client.clinicianStatus.toLowerCase() : '';
        let status = client.status.toLowerCase();
        // let clientID = client.clientID ? client.clientID.toLowerCase() : '';
        // let clientID = client.clientID ? '#' + parseInt(client.clientID.replace('#', ''), 10).toString() : '';
        let clientID = client.clientID ? client.clientID : '';
        let arrayClinicianName = state.searchClientActivityClinicianName ? client.arrayClinicianName : '';
        let arrayClinicianStatus = state.searchClientActivityClinicianStatus ? client.arrayClinicianStatus : '';
        let contactDateFormat = client.contactDateFormat ? client.contactDateFormat : ''
        let admissionDateFormat = client.admissionDateFormat ? client.admissionDateFormat : ''
        let dischargeDateFormat = client.dischargeDateFormat ? client.dischargeDateFormat : ''
        let arrayClinicianProgram = state.searchClientActivityClinicianProgram ? (client.arrayClinicianProgram ? client.arrayClinicianProgram : '') : ''

        var dateFrom = state.searchClientActivityStartDate.split('/')
        var dateTo = state.searchClientActivityEndDate.split('/')
        var dateCheckContact = contactDateFormat ? contactDateFormat.split('/') : ''
        var dateCheckAdmission = admissionDateFormat ? admissionDateFormat.split('/') : ''
        var dateCheckDischarge = dischargeDateFormat ? dischargeDateFormat.split('/') : ''

        var from = new Date(dateFrom[2], parseInt(dateFrom[1])-1, dateFrom[0])
        var to   = new Date(dateTo[2], parseInt(dateTo[1])-1, dateTo[0])
        var checkContact = new Date(dateCheckContact[2], parseInt(dateCheckContact[1])-1, dateCheckContact[0])
        var checkAdmission = new Date(dateCheckAdmission[2], parseInt(dateCheckAdmission[1])-1, dateCheckAdmission[0])
        var checkDischarge = new Date(dateCheckDischarge[2], parseInt(dateCheckDischarge[1])-1, dateCheckDischarge[0])

        var validateStatus = status === 'closed - received information' ? 'closed – received information' : status === 'closed - referred to oasas program' ? 'closed – referred to oasas program' : status

        // if(clientName.indexOf(state.searchClientActivityName) === 0 && clinicianName.indexOf(state.searchClientActivityClinicianName) === 0 && region.indexOf(state.searchClientActivityClinicianRegion) === 0 && clinicianStatus.indexOf(state.searchClientActivityClinicianStatus) === 0 && status.indexOf(state.searchClientActivityStatus) === 0 && clientID.indexOf(state.searchClientActivityClientID) === 0) {
        if(!state.searchClientActivityStartDate || !state.searchClientActivityEndDate) {
          if(state.searchClientActivityClinicianRegion === 'NYC') {
            if((region.includes(state.searchClientActivityClinicianRegion) || region.includes('Bronx') || region.includes('Brooklyn') || region.includes('Manhattan') || region.includes('Queens') || region.includes('Staten Island')) && clientName.indexOf(state.searchClientActivityName) === 0 && arrayClinicianName.includes(state.searchClientActivityClinicianName) && arrayClinicianStatus.includes(state.searchClientActivityClinicianStatus) && arrayClinicianProgram.includes(state.searchClientActivityClinicianProgram) && validateStatus.indexOf(state.searchClientActivityStatus) === 0 && clientID.indexOf(state.searchClientActivityClientID) === 0) {
              arregloFiltrado.push(client)
            }
          } else {
            if(clientName.indexOf(state.searchClientActivityName) === 0 && arrayClinicianName.includes(state.searchClientActivityClinicianName) && region.includes(state.searchClientActivityClinicianRegion) && arrayClinicianStatus.includes(state.searchClientActivityClinicianStatus) && arrayClinicianProgram.includes(state.searchClientActivityClinicianProgram) && validateStatus.indexOf(state.searchClientActivityStatus) === 0 && clientID.indexOf(state.searchClientActivityClientID) === 0) {
              arregloFiltrado.push(client)
            }
          }
        } else if(checkContact >= from && checkContact <= to || checkAdmission >= from && checkAdmission <= to || checkDischarge >= from && checkDischarge <= to) {
          if(state.searchClientActivityClinicianRegion === 'NYC') {
            if((region.includes(state.searchClientActivityClinicianRegion) || region.includes('Bronx') || region.includes('Brooklyn') || region.includes('Manhattan') || region.includes('Queens') || region.includes('Staten Island')) && clientName.indexOf(state.searchClientActivityName) === 0 && arrayClinicianName.includes(state.searchClientActivityClinicianName) && arrayClinicianStatus.includes(state.searchClientActivityClinicianStatus) && arrayClinicianProgram.includes(state.searchClientActivityClinicianProgram) && validateStatus.indexOf(state.searchClientActivityStatus) === 0 && clientID.indexOf(state.searchClientActivityClientID) === 0) {
              arregloFiltrado.push(client)
            }
          } else {
            if(clientName.indexOf(state.searchClientActivityName) === 0 && arrayClinicianName.includes(state.searchClientActivityClinicianName) && region.includes(state.searchClientActivityClinicianRegion) && arrayClinicianStatus.includes(state.searchClientActivityClinicianStatus) && arrayClinicianProgram.includes(state.searchClientActivityClinicianProgram) && validateStatus.indexOf(state.searchClientActivityStatus) === 0 && clientID.indexOf(state.searchClientActivityClientID) === 0) {
              arregloFiltrado.push(client)
            }
          }
        }
      }
      return arregloFiltrado
    },
    // return array: reports general search
    arrayFiltradoReportsGeneral(state) {
      if(!state.searchReportsGeneralStartDate) {
        state.searchReportsGeneralStartDate = ''
      }

      if(!state.searchReportsGeneralEndDate) {
        state.searchReportsGeneralEndDate = ''
      }

      if(!state.searchReportsGeneralClinicianName) {
        state.searchReportsGeneralClinicianName = ''
      }

      if(!state.searchReportsGeneralRegion) {
        state.searchReportsGeneralRegion = ''
      }

      // if(!state.searchReportsGeneralProgress) {
      //   state.searchReportsGeneralProgress = ''
      // }

      // if(!state.searchReportsGeneralLanguage) {
      //   state.searchReportsGeneralLanguage = ''
      // }

      let arregloFiltrado = []
      for (let rGeneral of state.reportsGeneral) {
        let contactDate = rGeneral.contactDateFormat
        let clinicianName = rGeneral.clinician
        let region = rGeneral.region ? rGeneral.region.toLowerCase() : ''
        // let language = rGeneral.language ? rGeneral.language.toLowerCase() : ''
        // let progress = rGeneral.status ? rGeneral.status.toLowerCase() : ''

        var dateFrom = state.searchReportsGeneralStartDate.split('/')
        var dateTo = state.searchReportsGeneralEndDate.split('/')
        var dateCheck = contactDate.split('/')

        var from = new Date(dateFrom[2], parseInt(dateFrom[1])-1, dateFrom[0])
        var to   = new Date(dateTo[2], parseInt(dateTo[1])-1, dateTo[0])
        var check = new Date(dateCheck[2], parseInt(dateCheck[1])-1, dateCheck[0])

        if(!state.searchReportsGeneralStartDate || !state.searchReportsGeneralEndDate) {
          if(region.indexOf(state.searchReportsGeneralRegion) === 0 && clinicianName.indexOf(state.searchReportsGeneralClinicianName) === 0) {
            arregloFiltrado.push(rGeneral)
          }
        } else if(check >= from && check <= to) {
          if(region.indexOf(state.searchReportsGeneralRegion) === 0 && clinicianName.indexOf(state.searchReportsGeneralClinicianName) === 0) {
            arregloFiltrado.push(rGeneral)
          }
        }
      }
      return arregloFiltrado
    },
    // return array: reports general claims search
    arrayFiltradoReportsGeneralClaims(state) {
      if(!state.searchReportsGeneralStartDate) {
        state.searchReportsGeneralStartDate = ''
      }

      if(!state.searchReportsGeneralEndDate) {
        state.searchReportsGeneralEndDate = ''
      }

      if(!state.searchReportsGeneralClinicianName) {
        state.searchReportsGeneralClinicianName = ''
      }

      if(!state.searchReportsGeneralClientName) {
        state.searchReportsGeneralClientName = ''
      }

      if(!state.searchReportsGeneralnoIndividualSessions) {
        state.searchReportsGeneralnoIndividualSessions = ''
      }

      if(!state.searchReportsGeneralnoGroupSessions) {
        state.searchReportsGeneralnoGroupSessions = ''
      }

      if(!state.searchReportsGeneralnoRemoteSessions) {
        state.searchReportsGeneralnoRemoteSessions = ''
      }

      if(!state.searchReportsGeneralnoAssessmentSessions) {
        state.searchReportsGeneralnoAssessmentSessions = ''
      }
      
      if(!state.searchReportsGeneralnoCoupleSessions) {
       state.searchReportsGeneralnoCoupleSessions = ''
      }

      if(!state.searchReportsGeneralnoFamilySessions) {
        state.searchReportsGeneralnoFamilySessions = ''
      }

      if(!state.searchReportsGeneralisInsuranceUtilizedForClaim) {
        state.searchReportsGeneralisInsuranceUtilizedForClaim = ''
      }

      if(!state.searchReportsGeneralclientProgressThisMonth) {
        state.searchReportsGeneralclientProgressThisMonth = ''
      }

      if(!state.searchReportsGeneralclientReportsSatisfactionWithTreatmentSessions) {
        state.searchReportsGeneralclientReportsSatisfactionWithTreatmentSessions = ''
      }

      if(!state.searchReportsGeneraldidClientProvideCopay) {
        state.searchReportsGeneraldidClientProvideCopay = ''
      }

      if(!state.searchReportsGeneraldidClientUtilizeSlidingScale) {
        state.searchReportsGeneraldidClientUtilizeSlidingScale = ''
      }

      if(!state.searchReportsGeneralRegion) {
        state.searchReportsGeneralRegion = ''
      }

      if(!state.searchReportsGeneralProgram) {
        state.searchReportsGeneralProgram = ''
      }

      // if(!state.searchReportsGeneralLanguage) {
      //   state.searchReportsGeneralLanguage = ''
      // }

      let arregloFiltrado = []
      var countAverageCopayPerSessionAtomic = 0

      for (let rGeneral of state.reportsGeneralClaims) {
        let claimDate = rGeneral.claimDate
        let clinicianName = rGeneral.clinician
        let clientName = rGeneral.client
        let noIndividualSessions = rGeneral.noIndividualSessions
        let noGroupSessions = rGeneral.noGroupSessions
        let noRemoteSessions = rGeneral.noRemoteSessions
        let noAssessmentSessions = rGeneral.noAssessmentSessions ? rGeneral.noAssessmentSessions : ''
        let noCoupleSessions = rGeneral.noCoupleSessions ? rGeneral.noCoupleSessions : ''
        let noFamilySessions = rGeneral.noFamilySessions ? rGeneral.noFamilySessions : ''
        let isInsuranceUtilizedForClaim = rGeneral.isInsuranceUtilizedForClaim ? rGeneral.isInsuranceUtilizedForClaim : ''
        let clientProgressThisMonth = rGeneral.clientProgressThisMonth ? rGeneral.clientProgressThisMonth : ''
        let clientReportsSatisfactionWithTreatmentSessions = rGeneral.clientReportsSatisfactionWithTreatmentSessions ? rGeneral.clientReportsSatisfactionWithTreatmentSessions : ''
        let didClientProvideCopay = rGeneral.didClientProvideCopay ? rGeneral.didClientProvideCopay : ''
        let didClientUtilizeSlidingScale = rGeneral.didClientUtilizeSlidingScale ? rGeneral.didClientUtilizeSlidingScale : ''
        // let region = rGeneral.regionClinician ? rGeneral.regionClinician.toLowerCase() : ''
        let region = rGeneral.regionBorough ? rGeneral.regionBorough : rGeneral.regionClinician ? rGeneral.regionClinician : ''
        let program = state.searchReportsGeneralProgram ? (rGeneral.clinicianProgram ? rGeneral.clinicianProgram : '') : ''
        countAverageCopayPerSessionAtomic += parseFloat(rGeneral.amountBilledToCFE)
        // let language = rGeneral.language ? rGeneral.language.toLowerCase() : ''

        var dateFrom = state.searchReportsGeneralStartDate.split('/')
        var dateTo = state.searchReportsGeneralEndDate.split('/')
        var dateCheck = claimDate.split('/')

        var from = new Date(dateFrom[2], parseInt(dateFrom[1])-1, dateFrom[0])
        var to   = new Date(dateTo[2], parseInt(dateTo[1])-1, dateTo[0])
        var check = new Date(dateCheck[2], parseInt(dateCheck[1])-1, dateCheck[0])

        if(!state.searchReportsGeneralStartDate || !state.searchReportsGeneralEndDate) {
          if(state.searchReportsGeneralRegion === 'NYC') {
            if((region.includes(state.searchReportsGeneralRegion) || region.includes('Bronx') || region.includes('Brooklyn') || region.includes('Manhattan') || region.includes('Queens') || region.includes('Staten Island')) && clinicianName.indexOf(state.searchReportsGeneralClinicianName) === 0 && clientName.indexOf(state.searchReportsGeneralClientName) === 0 && noIndividualSessions.indexOf(state.searchReportsGeneralnoIndividualSessions) === 0 && noGroupSessions.indexOf(state.searchReportsGeneralnoGroupSessions) === 0 && noRemoteSessions.indexOf(state.searchReportsGeneralnoRemoteSessions) === 0 && noAssessmentSessions.indexOf(state.searchReportsGeneralnoAssessmentSessions) === 0 && noCoupleSessions.indexOf(state.searchReportsGeneralnoCoupleSessions) === 0 && noFamilySessions.indexOf(state.searchReportsGeneralnoFamilySessions) === 0 && isInsuranceUtilizedForClaim.indexOf(state.searchReportsGeneralisInsuranceUtilizedForClaim) === 0 && clientProgressThisMonth.indexOf(state.searchReportsGeneralclientProgressThisMonth) === 0 && clientReportsSatisfactionWithTreatmentSessions.indexOf(state.searchReportsGeneralclientReportsSatisfactionWithTreatmentSessions) === 0 && didClientProvideCopay.indexOf(state.searchReportsGeneraldidClientProvideCopay) === 0 && didClientUtilizeSlidingScale.indexOf(state.searchReportsGeneraldidClientUtilizeSlidingScale) === 0 && program.indexOf(state.searchReportsGeneralProgram) === 0) {
              rGeneral.totalCopay = countAverageCopayPerSessionAtomic
              arregloFiltrado.push(rGeneral)
            }
          } else {
            if(region.indexOf(state.searchReportsGeneralRegion) === 0 && clinicianName.indexOf(state.searchReportsGeneralClinicianName) === 0 && clientName.indexOf(state.searchReportsGeneralClientName) === 0 && noIndividualSessions.indexOf(state.searchReportsGeneralnoIndividualSessions) === 0 && noGroupSessions.indexOf(state.searchReportsGeneralnoGroupSessions) === 0 && noRemoteSessions.indexOf(state.searchReportsGeneralnoRemoteSessions) === 0 && noAssessmentSessions.indexOf(state.searchReportsGeneralnoAssessmentSessions) === 0 && noCoupleSessions.indexOf(state.searchReportsGeneralnoCoupleSessions) === 0 && noFamilySessions.indexOf(state.searchReportsGeneralnoFamilySessions) === 0 && isInsuranceUtilizedForClaim.indexOf(state.searchReportsGeneralisInsuranceUtilizedForClaim) === 0 && clientProgressThisMonth.indexOf(state.searchReportsGeneralclientProgressThisMonth) === 0 && clientReportsSatisfactionWithTreatmentSessions.indexOf(state.searchReportsGeneralclientReportsSatisfactionWithTreatmentSessions) === 0 && didClientProvideCopay.indexOf(state.searchReportsGeneraldidClientProvideCopay) === 0 && didClientUtilizeSlidingScale.indexOf(state.searchReportsGeneraldidClientUtilizeSlidingScale) === 0 && program.indexOf(state.searchReportsGeneralProgram) === 0) {
              rGeneral.totalCopay = countAverageCopayPerSessionAtomic
              arregloFiltrado.push(rGeneral)
            }
          }
        } else {
          if(claimDate) {
            if(check >= from && check <= to) {
              if(state.searchReportsGeneralRegion === 'NYC') {
                if((region.includes(state.searchReportsGeneralRegion) || region.includes('Bronx') || region.includes('Brooklyn') || region.includes('Manhattan') || region.includes('Queens') || region.includes('Staten Island')) && clinicianName.indexOf(state.searchReportsGeneralClinicianName) === 0 && clientName.indexOf(state.searchReportsGeneralClientName) === 0 && noIndividualSessions.indexOf(state.searchReportsGeneralnoIndividualSessions) === 0 && noGroupSessions.indexOf(state.searchReportsGeneralnoGroupSessions) === 0 && noRemoteSessions.indexOf(state.searchReportsGeneralnoRemoteSessions) === 0 && noAssessmentSessions.indexOf(state.searchReportsGeneralnoAssessmentSessions) === 0 && noCoupleSessions.indexOf(state.searchReportsGeneralnoCoupleSessions) === 0 && noFamilySessions.indexOf(state.searchReportsGeneralnoFamilySessions) === 0 && isInsuranceUtilizedForClaim.indexOf(state.searchReportsGeneralisInsuranceUtilizedForClaim) === 0 && clientProgressThisMonth.indexOf(state.searchReportsGeneralclientProgressThisMonth) === 0 && clientReportsSatisfactionWithTreatmentSessions.indexOf(state.searchReportsGeneralclientReportsSatisfactionWithTreatmentSessions) === 0 && didClientProvideCopay.indexOf(state.searchReportsGeneraldidClientProvideCopay) === 0 && didClientUtilizeSlidingScale.indexOf(state.searchReportsGeneraldidClientUtilizeSlidingScale) === 0 && program.indexOf(state.searchReportsGeneralProgram) === 0) {
                  rGeneral.totalCopay = countAverageCopayPerSessionAtomic
                  arregloFiltrado.push(rGeneral)
                }
              } else {
                if(region.indexOf(state.searchReportsGeneralRegion) === 0 && clinicianName.indexOf(state.searchReportsGeneralClinicianName) === 0 && clientName.indexOf(state.searchReportsGeneralClientName) === 0 && noIndividualSessions.indexOf(state.searchReportsGeneralnoIndividualSessions) === 0 && noGroupSessions.indexOf(state.searchReportsGeneralnoGroupSessions) === 0 && noRemoteSessions.indexOf(state.searchReportsGeneralnoRemoteSessions) === 0 && noAssessmentSessions.indexOf(state.searchReportsGeneralnoAssessmentSessions) === 0 && noCoupleSessions.indexOf(state.searchReportsGeneralnoCoupleSessions) === 0 && noFamilySessions.indexOf(state.searchReportsGeneralnoFamilySessions) === 0 && isInsuranceUtilizedForClaim.indexOf(state.searchReportsGeneralisInsuranceUtilizedForClaim) === 0 && clientProgressThisMonth.indexOf(state.searchReportsGeneralclientProgressThisMonth) === 0 && clientReportsSatisfactionWithTreatmentSessions.indexOf(state.searchReportsGeneralclientReportsSatisfactionWithTreatmentSessions) === 0 && didClientProvideCopay.indexOf(state.searchReportsGeneraldidClientProvideCopay) === 0 && didClientUtilizeSlidingScale.indexOf(state.searchReportsGeneraldidClientUtilizeSlidingScale) === 0 && program.indexOf(state.searchReportsGeneralProgram) === 0) {
                  rGeneral.totalCopay = countAverageCopayPerSessionAtomic
                  arregloFiltrado.push(rGeneral)
                }
              }
            }
          }
        }
      }
      return arregloFiltrado
    },
    // return array: reports general Individual Clients Seen search
    arrayFiltradoReportsGeneralIndividualClientsSeen(state) {
      // if(!state.searchReportsGeneralStartDate) {
      //   state.searchReportsGeneralStartDate = ''
      // }

      // if(!state.searchReportsGeneralEndDate) {
      //   state.searchReportsGeneralEndDate = ''
      // }

      // if(!state.searchReportsGeneralClinicianName) {
      //   state.searchReportsGeneralClinicianName = ''
      // }

      // if(!state.searchReportsGeneralClientName) {
      //   state.searchReportsGeneralClientName = ''
      // }

      // if(!state.searchReportsGeneralnoIndividualSessions) {
      //   state.searchReportsGeneralnoIndividualSessions = ''
      // }

      // if(!state.searchReportsGeneralnoGroupSessions) {
      //   state.searchReportsGeneralnoGroupSessions = ''
      // }

      // if(!state.searchReportsGeneralnoRemoteSessions) {
      //   state.searchReportsGeneralnoRemoteSessions = ''
      // }

      // if(!state.searchReportsGeneralnoAssessmentSessions) {
      //   state.searchReportsGeneralnoAssessmentSessions = ''
      // }
      
      // if(!state.searchReportsGeneralnoCoupleSessions) {
      //  state.searchReportsGeneralnoCoupleSessions = ''
      // }

      // if(!state.searchReportsGeneralnoFamilySessions) {
      //   state.searchReportsGeneralnoFamilySessions = ''
      // }

      // if(!state.searchReportsGeneralisInsuranceUtilizedForClaim) {
      //   state.searchReportsGeneralisInsuranceUtilizedForClaim = ''
      // }

      // if(!state.searchReportsGeneralclientProgressThisMonth) {
      //   state.searchReportsGeneralclientProgressThisMonth = ''
      // }

      // if(!state.searchReportsGeneralclientReportsSatisfactionWithTreatmentSessions) {
      //   state.searchReportsGeneralclientReportsSatisfactionWithTreatmentSessions = ''
      // }

      // if(!state.searchReportsGeneraldidClientProvideCopay) {
      //   state.searchReportsGeneraldidClientProvideCopay = ''
      // }

      // if(!state.searchReportsGeneraldidClientUtilizeSlidingScale) {
      //   state.searchReportsGeneraldidClientUtilizeSlidingScale = ''
      // }

      // if(!state.searchReportsGeneralRegion) {
      //   state.searchReportsGeneralRegion = ''
      // }

      // if(!state.searchReportsGeneralLanguage) {
      //   state.searchReportsGeneralLanguage = ''
      // }

      var startYearDate = '01/12/2017'

      var actualDate = moment().format('MM/YYYY')
      let arregloFiltrado = []
      

      do {
        var countClaims = 0
        var includeInFilterDate = false

        var dateSplit = startYearDate.split('/')
        var dateDestruct = new Date(dateSplit[2], parseInt(dateSplit[1])-1, dateSplit[0])
        var addMonth = moment(dateDestruct).add(1, 'months')
        var addMonthFormat = moment(addMonth).format('MM/YYYY')
        startYearDate = '01/'+addMonthFormat
        // console.log(state.reportsGeneralIndividualClientsSeen.length)
        // for (let rGeneral of state.reportsGeneralIndividualClientsSeen) {
        //   let clinicianName = rGeneral.clinician
        //   let clientName = rGeneral.client
        //   let noIndividualSessions = rGeneral.noIndividualSessions
        //   let noGroupSessions = rGeneral.noGroupSessions
        //   let noRemoteSessions = rGeneral.noRemoteSessions
        //   let noAssessmentSessions = rGeneral.noAssessmentSessions ? rGeneral.noAssessmentSessions : ''
        //   let noCoupleSessions = rGeneral.noCoupleSessions ? rGeneral.noCoupleSessions : ''
        //   let noFamilySessions = rGeneral.noFamilySessions ? rGeneral.noFamilySessions : ''
        //   let isInsuranceUtilizedForClaim = rGeneral.isInsuranceUtilizedForClaim ? rGeneral.isInsuranceUtilizedForClaim : ''
        //   let clientProgressThisMonth = rGeneral.clientProgressThisMonth ? rGeneral.clientProgressThisMonth : ''
        //   let clientReportsSatisfactionWithTreatmentSessions = rGeneral.clientReportsSatisfactionWithTreatmentSessions ? rGeneral.clientReportsSatisfactionWithTreatmentSessions : ''
        //   let didClientProvideCopay = rGeneral.didClientProvideCopay ? rGeneral.didClientProvideCopay : ''
        //   let didClientUtilizeSlidingScale = rGeneral.didClientUtilizeSlidingScale ? rGeneral.didClientUtilizeSlidingScale : ''
        //   let region = rGeneral.region ? rGeneral.region.toLowerCase() : ''
        //   // includeInFilterDate = false
        //   // let claimMonthYear = rGeneral.claimMonthYear
        //   // var createdDate = '01/' + rGeneral.created
        //   var dateFrom = state.searchReportsGeneralStartDate.split('/')
        //   var dateTo = state.searchReportsGeneralEndDate.split('/')
        //   var dateCheck = startYearDate.split('/')

        //   var from = new Date(dateFrom[2], parseInt(dateFrom[1])-1, dateFrom[0])
        //   var to   = new Date(dateTo[2], parseInt(dateTo[1])-1, dateTo[0])
        //   var check = new Date(dateCheck[2], parseInt(dateCheck[1])-1, dateCheck[0])

        //   if(!state.searchReportsGeneralStartDate || !state.searchReportsGeneralEndDate) {
        //     if(region.indexOf(state.searchReportsGeneralRegion) === 0 && clinicianName.indexOf(state.searchReportsGeneralClinicianName) === 0 && clientName.indexOf(state.searchReportsGeneralClientName) === 0 && noIndividualSessions.indexOf(state.searchReportsGeneralnoIndividualSessions) === 0 && noGroupSessions.indexOf(state.searchReportsGeneralnoGroupSessions) === 0 && noRemoteSessions.indexOf(state.searchReportsGeneralnoRemoteSessions) === 0 && noAssessmentSessions.indexOf(state.searchReportsGeneralnoAssessmentSessions) === 0 && noCoupleSessions.indexOf(state.searchReportsGeneralnoCoupleSessions) === 0 && noFamilySessions.indexOf(state.searchReportsGeneralnoFamilySessions) === 0 && isInsuranceUtilizedForClaim.indexOf(state.searchReportsGeneralisInsuranceUtilizedForClaim) === 0 && clientProgressThisMonth.indexOf(state.searchReportsGeneralclientProgressThisMonth) === 0 && clientReportsSatisfactionWithTreatmentSessions.indexOf(state.searchReportsGeneralclientReportsSatisfactionWithTreatmentSessions) === 0 && didClientProvideCopay.indexOf(state.searchReportsGeneraldidClientProvideCopay) === 0 && didClientUtilizeSlidingScale.indexOf(state.searchReportsGeneraldidClientUtilizeSlidingScale) === 0) {
        //       if(rGeneral.created === addMonthFormat) {
        //         countClaims += 1
        //       }
        //     }
        //   } else if(check >= from && check <= to) {
        //     includeInFilterDate = true
        //     if(region.indexOf(state.searchReportsGeneralRegion) === 0 && clinicianName.indexOf(state.searchReportsGeneralClinicianName) === 0 && clientName.indexOf(state.searchReportsGeneralClientName) === 0 && noIndividualSessions.indexOf(state.searchReportsGeneralnoIndividualSessions) === 0 && noGroupSessions.indexOf(state.searchReportsGeneralnoGroupSessions) === 0 && noRemoteSessions.indexOf(state.searchReportsGeneralnoRemoteSessions) === 0 && noAssessmentSessions.indexOf(state.searchReportsGeneralnoAssessmentSessions) === 0 && noCoupleSessions.indexOf(state.searchReportsGeneralnoCoupleSessions) === 0 && noFamilySessions.indexOf(state.searchReportsGeneralnoFamilySessions) === 0 && isInsuranceUtilizedForClaim.indexOf(state.searchReportsGeneralisInsuranceUtilizedForClaim) === 0 && clientProgressThisMonth.indexOf(state.searchReportsGeneralclientProgressThisMonth) === 0 && clientReportsSatisfactionWithTreatmentSessions.indexOf(state.searchReportsGeneralclientReportsSatisfactionWithTreatmentSessions) === 0 && didClientProvideCopay.indexOf(state.searchReportsGeneraldidClientProvideCopay) === 0 && didClientUtilizeSlidingScale.indexOf(state.searchReportsGeneraldidClientUtilizeSlidingScale) === 0) {
        //       if(rGeneral.created === addMonthFormat) {
        //         countClaims += 1
        //       }
        //     }
        //   }
        // }
        var resultCount = state.reportsGeneralIndividualClientsSeen.filter(item => item.created === addMonthFormat)

        // if(!state.searchReportsGeneralStartDate || !state.searchReportsGeneralEndDate) {
        //   arregloFiltrado.push({dateFormat: addMonthFormat, countClaims: resultCount.length})
        // } else if(includeInFilterDate) {
          arregloFiltrado.push({dateFormat: addMonthFormat, countClaims: resultCount.length})
        // }
      } while (actualDate !== addMonthFormat);
      // db.collection('reportGeneral').doc('clientsSeen').set({
      //   data: arregloFiltrado
      // })
      // console.log('second')
      return arregloFiltrado
    },
    // return array: reports call data
    arrayFiltradoReportsCallData(state) {
      if(!state.searchReportsCallDataStartDate) {
        state.searchReportsCallDataStartDate = ''
      }

      if(!state.searchReportsCallDataEndDate) {
        state.searchReportsCallDataEndDate = ''
      }

      if(!state.searchReportsCallDataRegion) {
        state.searchReportsCallDataRegion = ''
      }

      if(!state.searchReportsCallDataProgress) {
        state.searchReportsCallDataProgress = ''
      }

      if(!state.searchReportsCallDataLanguage) {
        state.searchReportsCallDataLanguage = ''
      }

      if(!state.searchReportsCallDataInsurance) {
        state.searchReportsCallDataInsurance = ''
      }

      if(!state.searchReportsCallDataWarmTransfer) {
        state.searchReportsCallDataWarmTransfer = ''
      }

      if(!state.searchReportsCallDataProgram) {
        state.searchReportsCallDataProgram = ''
      }

      let arregloFiltrado = []
      let totalInitialAppointment = 0
      let totalAdmission = 0
      for(let rCallData of state.reportsCallData) {
        // let region = rCallData.region ? rCallData.region : ''
        let region = rCallData.borough ? rCallData.borough : rCallData.region ? rCallData.region : ''
        let progress = rCallData.statusLogical ? rCallData.statusLogical : ''
        let language = rCallData.language ? rCallData.language : ''
        let insurance = rCallData.insurance ? rCallData.insurance : ''
        let warmTransfer = rCallData.warmTransfer ? rCallData.warmTransfer : ''
        let program = state.searchReportsCallDataProgram ? (rCallData.arrayClinicianProgram ? rCallData.arrayClinicianProgram : '') : ''

        var dateFrom = state.searchReportsCallDataStartDate.split('/')
        var dateTo = state.searchReportsCallDataEndDate.split('/')
        var dateCheck = rCallData.contactDateFormat.split('/')
        var dateCheckInitial = rCallData.initialAppointmentDateFormat ? rCallData.initialAppointmentDateFormat.split('/') : ''
        var dateCheckAdm = rCallData.admissionDateFormat ? rCallData.admissionDateFormat.split('/') : ''
        let dischargeDateFormat = rCallData.dischargeDateFormat ? rCallData.dischargeDateFormat : ''
        var dateCheckDischarge = dischargeDateFormat ? dischargeDateFormat.split('/') : ''

        var from = new Date(dateFrom[2], parseInt(dateFrom[1])-1, dateFrom[0])
        var to   = new Date(dateTo[2], parseInt(dateTo[1])-1, dateTo[0])
        var check = new Date(dateCheck[2], parseInt(dateCheck[1])-1, dateCheck[0])
        var checkInitial = new Date(dateCheckInitial[2], parseInt(dateCheckInitial[1])-1, dateCheckInitial[0])
        var checkAdm = new Date(dateCheckAdm[2], parseInt(dateCheckAdm[1])-1, dateCheckAdm[0])
        var checkDischarge = new Date(dateCheckDischarge[2], parseInt(dateCheckDischarge[1])-1, dateCheckDischarge[0])
        // console.log(check, from, to)
        // state.searchReportsCallDataRegion ? console.log(rCallData.id,checkInitial,region) : ''

        if(!state.searchReportsCallDataStartDate || !state.searchReportsCallDataEndDate) {
          rCallData.dateIn = 0
          rCallData.dateAd = 0
        }

        if(!state.searchReportsCallDataStartDate || !state.searchReportsCallDataEndDate) {
          if(state.searchReportsCallDataRegion === 'NYC') {
            if((region.includes(state.searchReportsCallDataRegion) || region.includes('Bronx') || region.includes('Brooklyn') || region.includes('Manhattan') || region.includes('Queens') || region.includes('Staten Island')) && progress.indexOf(state.searchReportsCallDataProgress) === 0 && language.indexOf(state.searchReportsCallDataLanguage) === 0 && insurance.indexOf(state.searchReportsCallDataInsurance) === 0 && warmTransfer.indexOf(state.searchReportsCallDataWarmTransfer) === 0 && program.includes(state.searchReportsCallDataProgram)) {
              rCallData.includeByContact = true
              rCallData.includeByAdmission = true
              arregloFiltrado.push(rCallData)
            }
          } else {
            if(region.indexOf(state.searchReportsCallDataRegion) === 0 && progress.indexOf(state.searchReportsCallDataProgress) === 0 && language.indexOf(state.searchReportsCallDataLanguage) === 0 && insurance.indexOf(state.searchReportsCallDataInsurance) === 0 && warmTransfer.indexOf(state.searchReportsCallDataWarmTransfer) === 0 && program.includes(state.searchReportsCallDataProgram)) {
              rCallData.includeByContact = true
              rCallData.includeByAdmission = true
              arregloFiltrado.push(rCallData)
            }
          }
        } 
        else if(check >= from && check <= to || checkAdm >= from && checkAdm <= to) {
        // else if(check >= from && check <= to) {
        // else if(check >= from && check <= to || checkAdm >= from && checkAdm <= to || checkDischarge >= from && checkDischarge <= to) {
          var includeByContact = false
          if(check >= from && check <= to) {
            includeByContact = true
          }
          
          var includeByAdmission = false
          if(checkAdm >= from && checkAdm <= to) {
            includeByAdmission = true
          }

          if(state.searchReportsCallDataRegion === 'NYC') {
            if((region.includes(state.searchReportsCallDataRegion) || region.includes('Bronx') || region.includes('Brooklyn') || region.includes('Manhattan') || region.includes('Queens') || region.includes('Staten Island')) && progress.indexOf(state.searchReportsCallDataProgress) === 0 && language.indexOf(state.searchReportsCallDataLanguage) === 0 && insurance.indexOf(state.searchReportsCallDataInsurance) === 0 && warmTransfer.indexOf(state.searchReportsCallDataWarmTransfer) === 0) {
              rCallData.includeByContact = includeByContact
              rCallData.includeByAdmission = includeByAdmission
              arregloFiltrado.push(rCallData)
            }
          } else {
            if(region.indexOf(state.searchReportsCallDataRegion) === 0 && progress.indexOf(state.searchReportsCallDataProgress) === 0 && language.indexOf(state.searchReportsCallDataLanguage) === 0 && insurance.indexOf(state.searchReportsCallDataInsurance) === 0 && warmTransfer.indexOf(state.searchReportsCallDataWarmTransfer) === 0 && program.includes(state.searchReportsCallDataProgram)) {
              rCallData.includeByContact = includeByContact
              rCallData.includeByAdmission = includeByAdmission
              arregloFiltrado.push(rCallData)
            }
          }
        }
        // arregloFiltrado.push(rCallData)
      }
      // console.log(totalInitialAppointment)
      
      return {arregloFiltrado, totalInitialAppointment, totalAdmission}
    },
    // return array: reports active treatment (client activity)
    arrayFiltradoReportsActiveTreatment(state) {
      if(!state.searchReportsActiveTreatmentStartDate) {
        state.searchReportsActiveTreatmentStartDate = ''
      }

      if(!state.searchReportsActiveTreatmentEndDate) {
        state.searchReportsActiveTreatmentEndDate = ''
      }

      if(!state.searchReportsActiveTreatmentRegion) {
        state.searchReportsActiveTreatmentRegion = ''
      }

      if(!state.searchReportsActiveTreatmentProgress) {
        state.searchReportsActiveTreatmentProgress = ''
      }

      if(!state.searchReportsActiveTreatmentLanguage) {
        state.searchReportsActiveTreatmentLanguage = ''
      }

      if(!state.searchReportsActiveTreatmentInsurance) {
        state.searchReportsActiveTreatmentInsurance = ''
      }

      if(!state.searchReportsActiveTreatmentProgram) {
        state.searchReportsActiveTreatmentProgram = ''
      }

      let arregloFiltrado = []
      var x = 0
      var y = 0
      // var counterAcT = 0
      for(let rActiveTreatment of state.reportsActiveTreatment) {
        // let region = rActiveTreatment.region ? rActiveTreatment.region : ''
        let region = rActiveTreatment.borough ? rActiveTreatment.borough : rActiveTreatment.region ? rActiveTreatment.region : ''
        let progress = rActiveTreatment.statusLogical ? rActiveTreatment.statusLogical : ''
        let language = rActiveTreatment.language ? rActiveTreatment.language : ''
        let insurance = rActiveTreatment.insurance ? rActiveTreatment.insurance : ''
        let program = state.searchReportsActiveTreatmentProgram ? (rActiveTreatment.arrayClinicianProgram ? rActiveTreatment.arrayClinicianProgram : '') : ''

        let contactDateFormat = rActiveTreatment.contactDateFormat ? rActiveTreatment.contactDateFormat : ''
        let admissionDateFormat = rActiveTreatment.dateOfAdmissionFormat ? rActiveTreatment.dateOfAdmissionFormat : ''
        let dischargeDateFormat = rActiveTreatment.dateOfDischargeFormat ? rActiveTreatment.dateOfDischargeFormat : ''

        var dateFrom = state.searchReportsActiveTreatmentStartDate.split('/')
        var dateTo = state.searchReportsActiveTreatmentEndDate.split('/')
        var dateCheck = rActiveTreatment.dateOfAdmissionFormat.split('/')
        // var dateCheck = rActiveTreatment.initialAppointmentDate.split('/')
        // var dateCheck = rActiveTreatment.contactDateFormat.split('/')
        var dateCheckContact = contactDateFormat ? contactDateFormat.split('/') : ''
        var dateCheckAdmission = admissionDateFormat ? admissionDateFormat.split('/') : ''
        var dateCheckDischarge = dischargeDateFormat ? dischargeDateFormat.split('/') : ''

        // var from = new Date(dateFrom[2], parseInt(dateFrom[1])-1, dateFrom[0])
        // var to   = new Date(dateTo[2], parseInt(dateTo[1])-1, dateTo[0])
        var checkContact = new Date(dateCheckContact[2], parseInt(dateCheckContact[1])-1, dateCheckContact[0])
        var checkAdmission = new Date(dateCheckAdmission[2], parseInt(dateCheckAdmission[1])-1, dateCheckAdmission[0])
        var checkDischarge = new Date(dateCheckDischarge[2], parseInt(dateCheckDischarge[1])-1, dateCheckDischarge[0])

        var from = new Date(dateFrom[2], parseInt(dateFrom[1])-1, dateFrom[0])
        var fromMoment = moment(from).add(1, 'day').format('DD/MM/YYYY')
        var fromMomentFomat = fromMoment.split("/")
        var fromStartPlus1 = new Date(fromMomentFomat[2], parseInt(fromMomentFomat[1])-1, fromMomentFomat[0])
        var to   = new Date(dateTo[2], parseInt(dateTo[1])-1, dateTo[0])
        var check = new Date(dateCheck[2], parseInt(dateCheck[1])-1, dateCheck[0])

        if(check <= to) {
          if(!dischargeDateFormat || checkDischarge >= to) {
            if(!rActiveTreatment.dateOfAdmission) {
              if(state.searchReportsActiveTreatmentRegion === 'NYC') {
                if(region.includes(state.searchReportsActiveTreatmentRegion) || region.includes('Bronx') || region.includes('Brooklyn') || region.includes('Manhattan') || region.includes('Queens') || region.includes('Staten Island')) {
                  x += 1
                }
              } else {
                if(region.indexOf(state.searchReportsActiveTreatmentRegion) === 0) {
                  x += 1
                }
              }
            } else {
              if(state.searchReportsActiveTreatmentRegion === 'NYC') {
                if(region.includes(state.searchReportsActiveTreatmentRegion) || region.includes('Bronx') || region.includes('Brooklyn') || region.includes('Manhattan') || region.includes('Queens') || region.includes('Staten Island')) {
                  if(rActiveTreatment.clinicianStatus === 'active') {
                    y += 1
                  }
                }
              } else {
                if(region.indexOf(state.searchReportsActiveTreatmentRegion) === 0) {
                  if(rActiveTreatment.clinicianStatus === 'active') {
                    y += 1
                  }
                }
              }
            }
          }
        }
        
        // if(rActiveTreatment.dateOfAdmission) {
          if(!state.searchReportsActiveTreatmentStartDate || !state.searchReportsActiveTreatmentEndDate) {
            if(state.searchReportsActiveTreatmentRegion === 'NYC') {
              if((region.includes(state.searchReportsActiveTreatmentRegion) || region.includes('Bronx') || region.includes('Brooklyn') || region.includes('Manhattan') || region.includes('Queens') || region.includes('Staten Island')) && program.includes(state.searchReportsActiveTreatmentProgram)) {
                arregloFiltrado.push(rActiveTreatment)
              }
            } else {
              if(region.indexOf(state.searchReportsActiveTreatmentRegion) === 0 && program.includes(state.searchReportsActiveTreatmentProgram)) {
                arregloFiltrado.push(rActiveTreatment)
              }
            }
          } else if(check < fromStartPlus1) {
            if(!dischargeDateFormat || checkDischarge > from) {
              if(state.searchReportsActiveTreatmentRegion === 'NYC') {
                if((region.includes(state.searchReportsActiveTreatmentRegion) || region.includes('Bronx') || region.includes('Brooklyn') || region.includes('Manhattan') || region.includes('Queens') || region.includes('Staten Island')) && program.includes(state.searchReportsActiveTreatmentProgram)) {
                  arregloFiltrado.push(rActiveTreatment)
                }
              } else {
                if(region.indexOf(state.searchReportsActiveTreatmentRegion) === 0 && program.includes(state.searchReportsActiveTreatmentProgram)) {
                  arregloFiltrado.push(rActiveTreatment)
                }
              }
            }
          }
        // }
        // arregloFiltrado.push(rActiveTreatment)
      }
      return {arregloFiltrado: arregloFiltrado, count: x, county: y}
    },
    // return array: reports active treatment (client claims)
    arrayFiltradoReportsActiveTreatmentClaims(state) {
      if(!state.searchReportsActiveTreatmentStartDate) {
        state.searchReportsActiveTreatmentStartDate = ''
      }

      if(!state.searchReportsActiveTreatmentEndDate) {
        state.searchReportsActiveTreatmentEndDate = ''
      }

      if(!state.searchReportsActiveTreatmentRegion) {
        state.searchReportsActiveTreatmentRegion = ''
      }

      if(!state.searchReportsActiveTreatmentProgress) {
        state.searchReportsActiveTreatmentProgress = ''
      }

      if(!state.searchReportsActiveTreatmentLanguage) {
        state.searchReportsActiveTreatmentLanguage = ''
      }

      if(!state.searchReportsActiveTreatmentInsurance) {
        state.searchReportsActiveTreatmentInsurance = ''
      }

      if(!state.searchReportsActiveTreatmentProgram) {
        state.searchReportsActiveTreatmentProgram = ''
      }

      let arregloFiltrado = []
      for(let rActiveTreatment of state.reportsActiveTreatmentClaims) {
        let region = rActiveTreatment.region ? rActiveTreatment.region : ''
        let language = rActiveTreatment.language ? rActiveTreatment.language : ''
        let insurance = rActiveTreatment.insurance ? rActiveTreatment.insurance : ''
        let program = state.searchReportsActiveTreatmentProgram ? (rActiveTreatment.arrayClinicianProgram ? rActiveTreatment.arrayClinicianProgram : '') : ''

        var dateFrom = state.searchReportsActiveTreatmentStartDate.split('/')
        var dateTo = state.searchReportsActiveTreatmentEndDate.split('/')
        var dateCheck = rActiveTreatment.dateOfClaim.split('/')

        var from = new Date(dateFrom[2], parseInt(dateFrom[1])-1, dateFrom[0])
        var to   = new Date(dateTo[2], parseInt(dateTo[1])-1, dateTo[0])
        var check = new Date(dateCheck[2], parseInt(dateCheck[1])-1, dateCheck[0])

        if(!state.searchReportsActiveTreatmentStartDate || !state.searchReportsActiveTreatmentEndDate) {
          if(region.indexOf(state.searchReportsActiveTreatmentRegion) === 0 && language.indexOf(state.searchReportsActiveTreatmentLanguage) === 0 && insurance.indexOf(state.searchReportsActiveTreatmentInsurance) === 0) {
            arregloFiltrado.push(rActiveTreatment)
          }
        } else if(check >= from && check <= to) {
          if(region.indexOf(state.searchReportsActiveTreatmentRegion) === 0 && language.indexOf(state.searchReportsActiveTreatmentLanguage) === 0 && insurance.indexOf(state.searchReportsActiveTreatmentInsurance) === 0 && program.includes(state.searchReportsActiveTreatmentProgram)) {
            arregloFiltrado.push(rActiveTreatment)
          }
        }
        // arregloFiltrado.push(rActiveTreatment)
      }
      return arregloFiltrado
    },
    // return array: reports discharge data
    arrayFiltradoReportsDischargeData(state) {
      if(!state.searchReportsDischargeDataStartDate) {
        state.searchReportsDischargeDataStartDate = ''
      }

      if(!state.searchReportsDischargeDataEndDate) {
        state.searchReportsDischargeDataEndDate = ''
      }

      if(!state.searchReportsDischargeDataRegion) {
        state.searchReportsDischargeDataRegion = ''
      }

      if(!state.searchReportsDischargeDataProgress) {
        state.searchReportsDischargeDataProgress = ''
      }

      if(!state.searchReportsDischargeDataLanguage) {
        state.searchReportsDischargeDataLanguage = ''
      }

      if(!state.searchReportsDischargeDataInsurance) {
        state.searchReportsDischargeDataInsurance = ''
      }

      if(!state.searchReportsDischargeDataProgram) {
        state.searchReportsDischargeDataProgram = ''
      }

      let arregloFiltrado = []
      for(let rDischargeData of state.reportsDischargeData) {
        // let region = rDischargeData.region ? rDischargeData.region : ''
        let region = rDischargeData.borough ? rDischargeData.borough : rDischargeData.region ? rDischargeData.region : ''
        let progress = rDischargeData.statusLogical ? rDischargeData.statusLogical : ''
        let language = rDischargeData.language ? rDischargeData.language : ''
        let insurance = rDischargeData.insurance ? rDischargeData.insurance : ''
        let program = state.searchReportsDischargeDataProgram ? (rDischargeData.arrayClinicianProgram ? rDischargeData.arrayClinicianProgram : '') : ''

        var dateFrom = state.searchReportsDischargeDataStartDate.split('/')
        var dateTo = state.searchReportsDischargeDataEndDate.split('/')
        var dateCheck = rDischargeData.dateOfDischargeFormat.split('/')

        var from = new Date(dateFrom[2], parseInt(dateFrom[1])-1, dateFrom[0])
        var to   = new Date(dateTo[2], parseInt(dateTo[1])-1, dateTo[0])
        var check = new Date(dateCheck[2], parseInt(dateCheck[1])-1, dateCheck[0])

        if(!state.searchReportsDischargeDataStartDate || !state.searchReportsDischargeDataEndDate) {
          if(state.searchReportsDischargeDataRegion === 'NYC') {
            if((region.includes(state.searchReportsDischargeDataRegion) || region.includes('Bronx') || region.includes('Brooklyn') || region.includes('Manhattan') || region.includes('Queens') || region.includes('Staten Island')) && progress.indexOf(state.searchReportsDischargeDataProgress) === 0 && language.indexOf(state.searchReportsDischargeDataLanguage) === 0 && insurance.indexOf(state.searchReportsDischargeDataInsurance) === 0 && program.includes(state.searchReportsDischargeDataProgram)) {
              arregloFiltrado.push(rDischargeData)
            }
          } else {
            if(region.indexOf(state.searchReportsDischargeDataRegion) === 0 && progress.indexOf(state.searchReportsDischargeDataProgress) === 0 && language.indexOf(state.searchReportsDischargeDataLanguage) === 0 && insurance.indexOf(state.searchReportsDischargeDataInsurance) === 0 && program.includes(state.searchReportsDischargeDataProgram)) {
              arregloFiltrado.push(rDischargeData)
            }
          }
        } else if(check >= from && check <= to) {
          if(state.searchReportsDischargeDataRegion === 'NYC') {
            if((region.includes(state.searchReportsDischargeDataRegion) || region.includes('Bronx') || region.includes('Brooklyn') || region.includes('Manhattan') || region.includes('Queens') || region.includes('Staten Island')) && progress.indexOf(state.searchReportsDischargeDataProgress) === 0 && language.indexOf(state.searchReportsDischargeDataLanguage) === 0 && insurance.indexOf(state.searchReportsDischargeDataInsurance) === 0 && program.includes(state.searchReportsDischargeDataProgram)) {
              arregloFiltrado.push(rDischargeData)
            }
          } else {
            if(region.indexOf(state.searchReportsDischargeDataRegion) === 0 && progress.indexOf(state.searchReportsDischargeDataProgress) === 0 && language.indexOf(state.searchReportsDischargeDataLanguage) === 0 && insurance.indexOf(state.searchReportsDischargeDataInsurance) === 0 && program.includes(state.searchReportsDischargeDataProgram)) {
              arregloFiltrado.push(rDischargeData)
            }
          }
        }
        // arregloFiltrado.push(rDischargeData)
      }
      return arregloFiltrado
    },
    // return array: reports insurance utilization
    arrayFiltradoReportsInsuranceUtilization(state) {
      if(!state.searchReportsInsuranceUtilizationStartDate) {
        state.searchReportsInsuranceUtilizationStartDate = ''
      }

      if(!state.searchReportsInsuranceUtilizationEndDate) {
        state.searchReportsInsuranceUtilizationEndDate = ''
      }

      if(!state.searchReportsInsuranceUtilizationClinicianName) {
        state.searchReportsInsuranceUtilizationClinicianName = ''
      }

      if(!state.searchReportsInsuranceUtilizationClientName) {
        state.searchReportsInsuranceUtilizationClientName = ''
      }

      if(!state.searchReportsInsuranceUtilizationRegion) {
        state.searchReportsInsuranceUtilizationRegion = ''
      }

      if(!state.searchReportsInsuranceUtilizationProgram) {
        state.searchReportsInsuranceUtilizationProgram = ''
      }

      if(!state.searchReportsInsuranceUtilizationIsClientInsured) {
        state.searchReportsInsuranceUtilizationIsClientInsured = ''
      }

      if(!state.searchReportsInsuranceUtilizationIsClientMatchedToaClinicianWhoAcceptsTheirInsurance) {
        state.searchReportsInsuranceUtilizationIsClientMatchedToaClinicianWhoAcceptsTheirInsurance = ''
      }

      if(!state.searchReportsInsuranceUtilizationIsTheClinicianBillingTheClientsInsurance) {
        state.searchReportsInsuranceUtilizationIsTheClinicianBillingTheClientsInsurance = ''
      }

      if(!state.searchReportsInsuranceUtilizationWhyInsuranceWasNotBilled) {
        state.searchReportsInsuranceUtilizationWhyInsuranceWasNotBilled = ''
      }

      let arregloFiltrado = []
      for(let rInsuranceUtilization of state.reportsInsuranceUtilization) {
        let clinicianName = state.searchReportsInsuranceUtilizationClinicianName ? (rInsuranceUtilization.clinician ? rInsuranceUtilization.clinician : '') : ''
        let clientName = rInsuranceUtilization.clientName ? rInsuranceUtilization.clientName : ''
        let clientID = rInsuranceUtilization.clientID ? rInsuranceUtilization.clientID : ''
        let region = rInsuranceUtilization.borough ? rInsuranceUtilization.borough : rInsuranceUtilization.region ? rInsuranceUtilization.region : ''
        let program = state.searchReportsInsuranceUtilizationProgram ? (rInsuranceUtilization.arrayClinicianProgram ? rInsuranceUtilization.arrayClinicianProgram : '') : ''
        let paymentAssessmentDate = state.searchReportsInsuranceUtilizationIsClientInsured ? (rInsuranceUtilization.paymentAssessmentDate ? rInsuranceUtilization.paymentAssessmentDate : '') : ''
        let paymentArrangement = state.searchReportsInsuranceUtilizationIsClientMatchedToaClinicianWhoAcceptsTheirInsurance ? (rInsuranceUtilization.paymentArrangement ? rInsuranceUtilization.paymentArrangement : '') : ''
        // let isTheClinicianBillingTheClientsInsurance = rInsuranceUtilization.isTheClinicianBillingTheClientsInsurance ? rInsuranceUtilization.isTheClinicianBillingTheClientsInsurance : ''
        let theClientInsuranceIdonotAccept = state.searchReportsInsuranceUtilizationWhyInsuranceWasNotBilled ? (rInsuranceUtilization.theClientInsuranceIdonotAccept ? rInsuranceUtilization.theClientInsuranceIdonotAccept : '') : ''

        var dateFrom = state.searchReportsInsuranceUtilizationStartDate.split('/')
        var dateTo = state.searchReportsInsuranceUtilizationEndDate.split('/')
        var dateCheck = rInsuranceUtilization.contactDateFormat.split('/')

        var from = new Date(dateFrom[2], parseInt(dateFrom[1])-1, dateFrom[0])
        var to   = new Date(dateTo[2], parseInt(dateTo[1])-1, dateTo[0])
        var check = new Date(dateCheck[2], parseInt(dateCheck[1])-1, dateCheck[0])
        
        // console.log(region.indexOf(state.searchReportsInsuranceUtilizationRegion) === 0,clinicianName.includes(state.searchReportsInsuranceUtilizationClinicianName),clientName.indexOf(state.searchReportsInsuranceUtilizationClientName) === 0,program.includes(state.searchReportsInsuranceUtilizationProgram),paymentAssessmentDate.includes(state.searchReportsInsuranceUtilizationIsClientInsured))
        // console.log(clientID, state.searchReportsInsuranceUtilizationClientName)
        if(!state.searchReportsInsuranceUtilizationStartDate || !state.searchReportsInsuranceUtilizationEndDate) {
          if(state.searchReportsInsuranceUtilizationRegion === 'NYC') {
            if((region.includes(state.searchReportsInsuranceUtilizationRegion) || region.includes('Bronx') || region.includes('Brooklyn') || region.includes('Manhattan') || region.includes('Queens') || region.includes('Staten Island')) && clinicianName.includes(state.searchReportsInsuranceUtilizationClinicianName) && clientID.indexOf(state.searchReportsInsuranceUtilizationClientName) === 0 && program.includes(state.searchReportsInsuranceUtilizationProgram) && paymentAssessmentDate.includes(state.searchReportsInsuranceUtilizationIsClientInsured) && paymentArrangement.includes(state.searchReportsInsuranceUtilizationIsClientMatchedToaClinicianWhoAcceptsTheirInsurance) && theClientInsuranceIdonotAccept.includes(state.searchReportsInsuranceUtilizationWhyInsuranceWasNotBilled)) {
              arregloFiltrado.push(rInsuranceUtilization)
            }
          } else {
            if(region.indexOf(state.searchReportsInsuranceUtilizationRegion) === 0 && clinicianName.includes(state.searchReportsInsuranceUtilizationClinicianName) && clientID.indexOf(state.searchReportsInsuranceUtilizationClientName) === 0 && program.includes(state.searchReportsInsuranceUtilizationProgram) && paymentAssessmentDate.includes(state.searchReportsInsuranceUtilizationIsClientInsured) && paymentArrangement.includes(state.searchReportsInsuranceUtilizationIsClientMatchedToaClinicianWhoAcceptsTheirInsurance) && theClientInsuranceIdonotAccept.includes(state.searchReportsInsuranceUtilizationWhyInsuranceWasNotBilled)) {
              arregloFiltrado.push(rInsuranceUtilization)
            }
          }
        } else if(check >= from && check <= to) {
          if(state.searchReportsInsuranceUtilizationRegion === 'NYC') {
            if((region.includes(state.searchReportsInsuranceUtilizationRegion) || region.includes('Bronx') || region.includes('Brooklyn') || region.includes('Manhattan') || region.includes('Queens') || region.includes('Staten Island')) && clinicianName.includes(state.searchReportsInsuranceUtilizationClinicianName) && clientID.indexOf(state.searchReportsInsuranceUtilizationClientName) === 0 && program.includes(state.searchReportsInsuranceUtilizationProgram) && paymentAssessmentDate.includes(state.searchReportsInsuranceUtilizationIsClientInsured) && paymentArrangement.includes(state.searchReportsInsuranceUtilizationIsClientMatchedToaClinicianWhoAcceptsTheirInsurance) && theClientInsuranceIdonotAccept.includes(state.searchReportsInsuranceUtilizationWhyInsuranceWasNotBilled)) {
              arregloFiltrado.push(rInsuranceUtilization)
            }
          } else {
            if(region.indexOf(state.searchReportsInsuranceUtilizationRegion) === 0 && clinicianName.includes(state.searchReportsInsuranceUtilizationClinicianName) && clientID.indexOf(state.searchReportsInsuranceUtilizationClientName) === 0 && program.includes(state.searchReportsInsuranceUtilizationProgram) && paymentAssessmentDate.includes(state.searchReportsInsuranceUtilizationIsClientInsured) && paymentArrangement.includes(state.searchReportsInsuranceUtilizationIsClientMatchedToaClinicianWhoAcceptsTheirInsurance) && theClientInsuranceIdonotAccept.includes(state.searchReportsInsuranceUtilizationWhyInsuranceWasNotBilled)) {
              arregloFiltrado.push(rInsuranceUtilization)
            }
          }
        }
      }
      return arregloFiltrado
    },
    // return array: reports finance information
    arrayFiltradoReportsFinanceInformation(state) {
      if(!state.searchReportsFinanceInformationStartDate) {
        state.searchReportsFinanceInformationStartDate = ''
      }

      if(!state.searchReportsFinanceInformationEndDate) {
        state.searchReportsFinanceInformationEndDate = ''
      }

      if(!state.searchReportsFinanceInformationClinicianName) {
        state.searchReportsFinanceInformationClinicianName = ''
      }

      if(!state.searchReportsFinanceInformationClientName) {
        state.searchReportsFinanceInformationClientName = ''
      }

      if(!state.searchReportsFinanceInformationRegion) {
        state.searchReportsFinanceInformationRegion = ''
      }

      if(!state.searchReportsFinanceInformationProgram) {
        state.searchReportsFinanceInformationProgram = ''
      }

      if(!state.searchReportsFinanceInformationInsuranceRates) {
        state.searchReportsFinanceInformationInsuranceRates = ''
      }

      if(!state.searchReportsFinanceInformationDidClientProvideCopay) {
        state.searchReportsFinanceInformationDidClientProvideCopay = ''
      }

      if(!state.searchReportsFinanceInformationDidclientUtilizeSlidingScale) {
        state.searchReportsFinanceInformationDidclientUtilizeSlidingScale = ''
      }

      let arregloFiltrado = []
      for(let rFinanceInformation of state.reportsFinanceInformation) {
        let claimDate = rFinanceInformation.claimDate
        let clinicianName = rFinanceInformation.clinician ? rFinanceInformation.clinician : ''
        let clientName = rFinanceInformation.client ? rFinanceInformation.client : ''
        let region = rFinanceInformation.borough ? rFinanceInformation.borough : rFinanceInformation.regionClinician ? rFinanceInformation.regionClinician : ''
        let program = rFinanceInformation.clinicianProgram ? rFinanceInformation.clinicianProgram : ''
        let didClientProvideCopay = rFinanceInformation.didClientProvideCopay ? 'Yes' : ''
        let slidingScale = rFinanceInformation.slidingScale ? 'Yes' : ''

        var dateFrom = state.searchReportsFinanceInformationStartDate.split('/')
        var dateTo = state.searchReportsFinanceInformationEndDate.split('/')
        var dateCheck = claimDate.split('/')

        var from = new Date(dateFrom[2], parseInt(dateFrom[1])-1, dateFrom[0])
        var to   = new Date(dateTo[2], parseInt(dateTo[1])-1, dateTo[0])
        var check = new Date(dateCheck[2], parseInt(dateCheck[1])-1, dateCheck[0])
        
        // console.log(region)
        if(!state.searchReportsFinanceInformationStartDate || !state.searchReportsFinanceInformationEndDate) {
          if(state.searchReportsFinanceInformationRegion === 'NYC') {
            if((region.includes(state.searchReportsFinanceInformationRegion) || region.includes('Bronx') || region.includes('Brooklyn') || region.includes('Manhattan') || region.includes('Queens') || region.includes('Staten Island')) && clinicianName.indexOf(state.searchReportsFinanceInformationClinicianName) === 0 && clientName.indexOf(state.searchReportsFinanceInformationClientName) === 0 && program.indexOf(state.searchReportsFinanceInformationProgram) === 0 && didClientProvideCopay.indexOf(state.searchReportsFinanceInformationDidClientProvideCopay) === 0 && slidingScale.indexOf(state.searchReportsFinanceInformationDidclientUtilizeSlidingScale) === 0) {
              arregloFiltrado.push(rFinanceInformation)
            }
          } else {
            // console.log(region,state.searchReportsFinanceInformationRegion)
            if(region.indexOf(state.searchReportsFinanceInformationRegion) === 0 && clinicianName.indexOf(state.searchReportsFinanceInformationClinicianName) === 0 && clientName.indexOf(state.searchReportsFinanceInformationClientName) === 0 && program.indexOf(state.searchReportsFinanceInformationProgram) === 0 && didClientProvideCopay.indexOf(state.searchReportsFinanceInformationDidClientProvideCopay) === 0 && slidingScale.indexOf(state.searchReportsFinanceInformationDidclientUtilizeSlidingScale) === 0) {
              arregloFiltrado.push(rFinanceInformation)
            }
          }
        } else if(check >= from && check <= to) {
          if(state.searchReportsFinanceInformationRegion === 'NYC') {
            if((region.includes(state.searchReportsFinanceInformationRegion) || region.includes('Bronx') || region.includes('Brooklyn') || region.includes('Manhattan') || region.includes('Queens') || region.includes('Staten Island')) && clinicianName.indexOf(state.searchReportsFinanceInformationClinicianName) === 0 && clientName.indexOf(state.searchReportsFinanceInformationClientName) === 0 && program.indexOf(state.searchReportsFinanceInformationProgram) === 0 && didClientProvideCopay.indexOf(state.searchReportsFinanceInformationDidClientProvideCopay) === 0 && slidingScale.indexOf(state.searchReportsFinanceInformationDidclientUtilizeSlidingScale) === 0) {
              arregloFiltrado.push(rFinanceInformation)
            }
          } else {
            if(region.indexOf(state.searchReportsFinanceInformationRegion) === 0 && clinicianName.indexOf(state.searchReportsFinanceInformationClinicianName) === 0 && clientName.indexOf(state.searchReportsFinanceInformationClientName) === 0 && program.indexOf(state.searchReportsFinanceInformationProgram) === 0 && didClientProvideCopay.indexOf(state.searchReportsFinanceInformationDidClientProvideCopay) === 0 && slidingScale.indexOf(state.searchReportsFinanceInformationDidclientUtilizeSlidingScale) === 0) {
              arregloFiltrado.push(rFinanceInformation)
            }
          }
        }
      }
      return arregloFiltrado
    },
    // return array: reports clinician
    arrayFiltradoReportsClinician(state) {
      if(!state.searchReportsClinicianStartDate) {
        state.searchReportsClinicianStartDate = ''
      }

      if(!state.searchReportsClinicianEndDate) {
        state.searchReportsClinicianEndDate = ''
      }

      if(!state.searchReportsClinicianClinicianName) {
        state.searchReportsClinicianClinicianName = ''
      }

      if(!state.searchReportsClinicianClientName) {
        state.searchReportsClinicianClientName = ''
      }

      if(!state.searchReportsClinicianRegion) {
        state.searchReportsClinicianRegion = ''
      }

      if(!state.searchReportsClinicianProgram) {
        state.searchReportsClinicianProgram = ''
      }

      if(!state.searchReportsClinicianClaimDateMonth) {
        state.searchReportsClinicianClaimDateMonth = ''
      }

      if(!state.searchReportsClinicianClaimDateYear) {
        state.searchReportsClinicianClaimDateYear = ''
      }

      let arregloFiltrado = []
      // if(state.searchReportsClinicianClinicianName) {
        for(let rClinician of state.reportsClinician) {
          let claimDate = rClinician.claimDate
          let clinicianName = rClinician.clinician ? rClinician.clinician : ''
          let clientName = rClinician.client ? rClinician.client : ''
          let region = rClinician.regionClinician ? rClinician.regionClinician : ''
          let program = rClinician.clinicianProgram ? rClinician.clinicianProgram : ''
          let claimDateMonth = rClinician.claimDateMonth ? rClinician.claimDateMonth.toString() : ''
          let claimDateYear = rClinician.claimDateYear ? rClinician.claimDateYear.toString() : ''

          var dateFrom = state.searchReportsClinicianStartDate.split('/')
          var dateTo = state.searchReportsClinicianEndDate.split('/')
          var dateCheck = claimDate.split('/')

          var from = new Date(dateFrom[2], parseInt(dateFrom[1])-1, dateFrom[0])
          var to   = new Date(dateTo[2], parseInt(dateTo[1])-1, dateTo[0])
          var check = new Date(dateCheck[2], parseInt(dateCheck[1])-1, dateCheck[0])
          
          // console.log(claimDateYear)
          if(!state.searchReportsClinicianStartDate || !state.searchReportsClinicianEndDate) {
            if(state.searchReportsClinicianRegion === 'NYC') {
              if((region.includes(state.searchReportsClinicianRegion) || region.includes('Bronx') || region.includes('Brooklyn') || region.includes('Manhattan') || region.includes('Queens') || region.includes('Staten Island')) && clinicianName.indexOf(state.searchReportsClinicianClinicianName) === 0 && clientName.indexOf(state.searchReportsClinicianClientName) === 0 && program.indexOf(state.searchReportsClinicianProgram) === 0 && claimDateMonth.indexOf(state.searchReportsClinicianClaimDateMonth) === 0 && claimDateYear.indexOf(state.searchReportsClinicianClaimDateYear) === 0) {
                arregloFiltrado.push(rClinician)
              }
            } else {
              if(region.indexOf(state.searchReportsClinicianRegion) === 0 && clinicianName.indexOf(state.searchReportsClinicianClinicianName) === 0 && clientName.indexOf(state.searchReportsClinicianClientName) === 0 && program.indexOf(state.searchReportsClinicianProgram) === 0 && claimDateMonth.indexOf(state.searchReportsClinicianClaimDateMonth) === 0 && claimDateYear.indexOf(state.searchReportsClinicianClaimDateYear) === 0) {
                arregloFiltrado.push(rClinician)
              }
            }
          } else if(check >= from && check <= to) {
            if(state.searchReportsClinicianRegion === 'NYC') {
              if((region.includes(state.searchReportsClinicianRegion) || region.includes('Bronx') || region.includes('Brooklyn') || region.includes('Manhattan') || region.includes('Queens') || region.includes('Staten Island')) && clinicianName.indexOf(state.searchReportsClinicianClinicianName) === 0 && clientName.indexOf(state.searchReportsClinicianClientName) === 0 && program.indexOf(state.searchReportsClinicianProgram) === 0 && claimDateMonth.indexOf(state.searchReportsClinicianClaimDateMonth) === 0 && claimDateYear.indexOf(state.searchReportsClinicianClaimDateYear) === 0) {
                arregloFiltrado.push(rClinician)
              }
            } else {
              if(region.indexOf(state.searchReportsClinicianRegion) === 0 && clinicianName.indexOf(state.searchReportsClinicianClinicianName) === 0 && clientName.indexOf(state.searchReportsClinicianClientName) === 0 && program.indexOf(state.searchReportsClinicianProgram) === 0 && claimDateMonth.indexOf(state.searchReportsClinicianClaimDateMonth) === 0 && claimDateYear.indexOf(state.searchReportsClinicianClaimDateYear) === 0) {
                arregloFiltrado.push(rClinician)
              }
            }
          }
        }
      // }
      return arregloFiltrado
    }
  },
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
  },
  strict: process.env.DEV,
})